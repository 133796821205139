export class KycService {

  getConfig = params => {
    const token = 'cHZXamFzS3pLZmoweE1yUXVPTDRoaU1oYm42U2dGSDRDRjcyN1l0SDNBRjdSMVVWdksxNTkxNzUyMDE3OiQyeSQxMCRjN0Z4czh2R0h1WEYvdXFZU2dVd2p1OHBZTlcxdFVCeWc0N2RQdm5lYm5lLmtwenNyZHFvLg==';

    return {
      params,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + token,
      },
    };
  };


  async postFace(params) {
    const token = 'cHZXamFzS3pLZmoweE1yUXVPTDRoaU1oYm42U2dGSDRDRjcyN1l0SDNBRjdSMVVWdksxNTkxNzUyMDE3OiQyeSQxMCRjN0Z4czh2R0h1WEYvdXFZU2dVd2p1OHBZTlcxdFVCeWc0N2RQdm5lYm5lLmtwenNyZHFvLg==';

    const nameWallet = 'GWALLET';
    const callBack = 'https://gwallet.services.snbxt.com/api/callback/';
    const email = 'office@ewallet.international';
    const service_url = 'https://api.shuftipro.com/';

    let verification_request = {
      'reference': `${nameWallet}_${Math.random()}`,
      'callback_url': callBack,
      'email': email,
      'country': params['country'],
      'verification_e': 'image_only',
      'show_privacy_policy': '1',
      'show_results': '1',
    };

    if (params['id_type'] === 'Face') {
      verification_request['face'] = {
        'proof': params['data_64'],
      };
    } else {
      //doc_type = ["passport", "id_card", "driving_license"]; params["doc_type"]
      if (params['section_type'] === 'IDENTIFICATION') {
        verification_request['document'] = {
          'proof': params['data_64'],
          'additional_proof': params['data_64_additional'],
          'supported_types': ["passport", "id_card", "driving_license"],
          'name': '',
          'dob': '',
          'issue_date': '',
          'expiry_date': '',
          'document_number': '',
          'allow_offline': '1',
          'fetch_enhanced_data': '1',
          'backside_proof_required': '0',
          'gender': '',
        };
      } else if (params['section_type'] === 'PROOF_ADDRESS') { //doc_type = ["utility_bill", "bank_statement"]; params["doc_type"]
        verification_request['address'] = {
          'proof': params['data_64'],
          'additional_proof': params['data_64_additional'],
          'supported_types': ["utility_bill", "bank_statement"],
          'name': '',
          'issue_date': '',
          'full_address': '',
          'address_fuzzy_match': '1',
          'backside_proof_required': '0',
        };
      }
    }

    let response = await fetch(service_url, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + token,
      },
      body: JSON.stringify(verification_request),
    }).then(function(response) {
      return response.json();
    }).then(function(data) {
      return data;
    });

    return response;
  }
}
