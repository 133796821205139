import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {TabPanel, TabView} from "primereact/tabview";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {loadProfile} from "../../util/commonFunctions";
import {MERCHANT_KEY} from "../../util/constants";

export class MyAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addAccountDialogDisplay: false,
            wallet_reference: '',
            transferDialogDisplay: false,
            sender_account: '',
            receiver_account: '',
            amountToTransfer: '',
            rowFrom: [],
            dataTableSelection: {},
            rowTo: [],
            currentPage: 1,
            rowsPerPage: 4,
            pageNumberOfPage: 1,
            walletView: 'all-wallet',
            selectedWallet: {},
            tab: 0,
            mcCommentTo: '',
            currencies: [{label: 'Select Currency', value: ''},
                {label: 'USD', value: 'USD'}],
            preferredCurrency: '',

            actionToConfirm: null,

            actionsDetailTabView: false,
            activeTabViewIndex: 1,
            dataTableDeposit: [],
            dataTableDepositSelected: {},
            dataTableTransfer: [],
            dataTableTransferSelected: {},
            dataTablePaypot: [],
            dataTablePaypotSelected: {},
            dataTableBestTrans: [],
            dataTableBestTransSelected: {}
        };

        this.loadAccounts = this.loadAccounts.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.addAccountSubmitHandler = this.addAccountSubmitHandler.bind(this);
        this.showActionsDetailTabView = this.showActionsDetailTabView.bind(this);
        this.backendService = new BackendService();
    }

    componentDidMount() {
        const self = this;
        loadProfile(this, this.backendService, null).then(resp => {
            if (resp !== null) {
                this.setState({profileData: resp});
                this.loadAccounts(resp, self);
            }
        });
    }

    loadAccounts = (profile, self) => {
        const profileData = profile;
        if (profileData !== null) {
            profileData.array_accounts.forEach(function (element) {
                if (element.dlast_balance != null) {
                    element.dlast_balance = element.dlast_balance.substring(0, 10);
                }
            });
            const ROWFROM = profileData.array_accounts;
            self.setState({rowFrom: ROWFROM});

            self.currentRows = ROWFROM.slice(self.indexOfFirstRow, self.indexOfLastRow);
        }
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
        }
        this.backendService.getAccountList(params).then(resp => {
            if (resp.status === 'Success') {
                const rowTo = [];
                resp.data.forEach(element => {
                    element.alias =  element.id + ' - ' + element.alias;

                    rowTo.push(element)
                });
                this.setState({rowTo});
            } else {
                this.messages.show({severity: 'warning', summary: 'Warning', detail: "Error in Service"});
            }
        }).catch(error => {
            if (this.messages !== null) {
                this.messages.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list`
                });
            }
        });
    };

    addAccountSubmitHandler = e => {
        e.preventDefault();
        if (this.state.wallet_reference === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Please give a valid info!'});
        } else {
            this.setState({
                wallet_reference: '',
                addAccountDialogDisplay: false,
            });
            this.growl.show({severity: 'success', summary: 'Success', detail: 'pending Wallet Added Successfully!'});
        }
    };
    transferSubmitHandler = e => {
        e.preventDefault();

        const {amountToTransfer, sender_account, receiver_account, wallet_reference} = this.state;

        if (amountToTransfer === '' || sender_account === '' || receiver_account === '' || wallet_reference === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Please give a valid info!'});
        } else {
            const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                merchant_key: MERCHANT_KEY,
                username: profileData.email,
                sender_account: sender_account.provider_accountid,
                receiver_account_id: receiver_account.id,
                amount: amountToTransfer,
                reference: wallet_reference,
                test: true
            }
            this.backendService.postTransferAccountBest(params).then(resp => {
                if (resp.status === 'Success') {
                    this.loadProfile();
                    this.setState({
                        transferDialogDisplay: false,
                        amountToTransfer: '',
                        sender_account: '',
                        reciver_account: '',
                        wallet_reference: '',
                    });
                    this.growl.show({severity: 'success', summary: 'Success', detail: 'Coin Moved Successfully!'});
                } else {
                    this.growl.show({severity: 'warning', summary: 'Warning', detail: 'Error in Service'});
                }
            }).catch(error => {
                this.setState({transferDialogDisplay: false});
                const errorObjectShow = `CATCH: ${JSON.stringify(
                    error.response.data.message,
                )}`;
                this.growl.show({
                    severity: 'warning',
                    summary: 'Warning',
                    detail: `Error in Service recipients list ${errorObjectShow}`
                });
            });
        }
    };

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="fa fa-paper-plane-o"
                        onClick={() => {
                            this.showActionsDetailTabView(rowData)
                        }}
                        className="p-button-rounded p-button-help p-button-outlined"/>
            </React.Fragment>
        );
    }

    showModal = modal => () => {
        this.setState({[modal]: true});
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        this.setState({
            [showModal]: true,
            actionToConfirm: action
        })
    }
    changeHandler = event => {
        this.setState({[event.target.name]: event.target.value});
    }

    showActionsDetailTabView(rowData) {
        // eslint-disable-next-line
        this.setState({actionsDetailTabView: true});
        this.backendService.postReportHistory({
            account_id: rowData.provider_accountid,
            date_ini: '',
            date_end: '',
        }).then(resp => {
            if (resp.status === 'success') {
                const lstDeposit = resp.data.lstDeposit !== null ? resp.data.lstDeposit : [];
                const lstWithdraw = resp.data.lstWithdraw  !== null ? resp.data.lstWithdraw : [];
                const lstPaidOut = resp.data.lstPaidOut  !== null ? resp.data.lstPaidOut : [];
                const lstBestTrans = resp.data.lstBestTrans !== null ? resp.data.lstBestTrans : [];
                lstDeposit.forEach(function (element) {
                    if (element.dcreated != null) {
                        element.dcreated = element.dcreated.substring(0, 10);
                    }
                });
                lstWithdraw.forEach(function (element) {
                    if (element.dcreated != null) {
                        element.dcreated = element.dcreated.substring(0, 10);
                    }
                });
                lstPaidOut.forEach(function (element) {
                    if (element.dcreated != null) {
                        element.dcreated = element.dcreated.substring(0, 10);
                    }
                });
                lstBestTrans.forEach(function (element) {
                    if (element.TransactionDate != null) {
                        element.TransactionDate = element.TransactionDate.substring(0, 10);
                    }
                });
                this.setState({
                    dataTableDeposit: lstDeposit,
                    dataTableTransfer: lstWithdraw,
                    dataTablePaypot: lstPaidOut,
                    dataTableBestTrans: lstBestTrans,
                });
            }

        }).catch(error => {
            this.growl.show({severity: 'error', summary: 'Error', detail: `Error in Service recipients list ${error}`});
        });
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const addAccountDialog = (
            <Dialog header="Add Account" visible={this.state.addAccountDialogDisplay} modal={true}
                    style={{width: '450px', background: "#1c2155"}}
                    contentStyle={{height: 160}}
                    footer={dialogFooter('addAccountDialogDisplay', this.addAccountSubmitHandler, 'Add')}
                    onHide={this.hideModal('addAccountDialogDisplay')}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amount" className="p-col-fixed" style={{width: '120px'}}>Account name:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Account name"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="account" className="p-col-fixed" style={{width: '120px'}}>Currency:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.currencies}
                                value={this.state.preferredCurrency}
                                placeholder="Select currency"
                                name="preferredCurrency"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const transferDialog = (
            <Dialog header="Move Funds" visible={this.state.transferDialogDisplay} modal={true}
                    style={{width: '550px', background: "#1c2155"}}
                    contentStyle={{height: 250}}
                    footer={dialogFooter('transferDialogDisplay', this.transferSubmitHandler, 'Move')}
                    onHide={this.hideModal('transferDialogDisplay')}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="from" className="p-col-fixed" style={{width: '160px'}}>From:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.rowFrom}
                                optionLabel="label"
                                value={this.state.sender_account}
                                placeholder="Sender account"
                                name="sender_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="to" className="p-col-fixed" style={{width: '160px'}}>To:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.rowTo}
                                optionLabel="alias"
                                value={this.state.receiver_account}
                                placeholder="Select receiver account"
                                name="receiver_account"
                                onChange={this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amountToTransfer" className="p-col-fixed"
                               style={{width: '160px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText value={this.state.amountToTransfer}
                                       placeholder="Amount"
                                       type="number"
                                       name="amountToTransfer"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="comment" className="p-col-fixed" style={{width: '160px'}}>Comment or
                            reference:</label>
                        <div className="p-col">
                            <InputText value={this.state.wallet_reference}
                                       placeholder="Add comment"
                                       name="wallet_reference"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const tabViewDetail = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-10">
                        <h2>Wallet Transaction Activity</h2>
                    </div>
                    <div className="p-col-2 ta-right">
                        <i className="pi pi-times" style={{'fontSize': '1.5em', color: '#E0C46C', cursor: 'pointer'}}
                           onClick={this.hideModal('actionsDetailTabView')}/>
                    </div>
                    <div className="p-col-12">
                        <TabView className="tabview-custom mt-20">
                            <TabPanel header="Deposit" leftIcon="fa fa-money">
                                <DataTable value={this.state.dataTableDeposit} selectionMode="single" paginator={true}
                                           rows={10} className="mt-20"
                                           selection={this.state.dataTableDepositSelected}
                                           onSelectionChange={event => this.setState({dataTableDepositSelected: event.value})}
                                           responsive={true}>
                                    <Column field='amount' header='Amount'/>
                                    <Column field='dcreated' header='Created At' className="ta-center"/>
                                    <Column field='merchant_user' header='Merchant' className="ta-center"/>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Transfers" rightIcon="fa fa-exchange">
                                <DataTable value={this.state.dataTableTransfer} selectionMode="single" paginator={true}
                                           rows={10} className="mt-20"
                                           selection={this.state.dataTableTransferSelected}
                                           onSelectionChange={event => this.setState({dataTableTransferSelected: event.value})}
                                           responsive={true}>
                                    <Column field='amount' header='Amount'/>
                                    <Column field='dcreated' header='Created At' className="ta-center"/>
                                    <Column field='merchant_user' header='Merchant' className="ta-center"/>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Paid out" rightIcon="fa fa-money">
                                <DataTable value={this.state.dataTablePaypot} selectionMode="single" paginator={true}
                                           rows={10} className="mt-20"
                                           selection={this.state.dataTablePaypotSelected}
                                           onSelectionChange={event => this.setState({dataTablePaypotSelected: event.value})}
                                           responsive={true}>
                                    <Column field='amount' header='Amount'/>
                                    <Column field='dcreated' header='Created At' className="ta-center"/>
                                    <Column field='merchant_user' header='Merchant' className="ta-center"/>
                                </DataTable>
                            </TabPanel>
                            <TabPanel header="Others" rightIcon="fa fa-exchange">
                                <DataTable value={this.state.dataTableBestTrans} selectionMode="single" paginator={true}
                                           rows={10} className="mt-20"
                                           selection={this.state.dataTableBestTransSelected}
                                           onSelectionChange={event => this.setState({dataTableBestTransSelected: event.value})}
                                           responsive={true}>
                                    <Column field='TransactionNumber' header='Transaction Number'/>
                                    <Column field='TransactionDate' header='Date' className="ta-center"/>
                                    <Column field='TransactionDesc' header='Description' className="ta-center"/>
                                    <Column field='ClientReferenceId' header='Reference' className="ta-center"/>
                                    <Column field='Amount' header='Amount' className="ta-center"/>
                                    <Column field='Balance' header='Balance' className="ta-center"/>
                                </DataTable>
                            </TabPanel>
                        </TabView>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-6">
                                <h2 className="mb-20">Accounts Transaction Activity</h2>
                            </div>
                            <div className="p-col-6 text-right">
                                <Button label="Add Account" onClick={this.showModal('addAccountDialogDisplay')}/>
                                <Button label="Transfer to" onClick={this.showModal('transferDialogDisplay')}/>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.rowFrom} selectionMode="single" paginator={true} rows={10}
                                           selection={this.state.dataTableSelection}
                                           onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                           responsive={true}>
                                    <Column field='label' header='Name'/>
                                    <Column field='last_balance' header='Balance' className="ta-center"/>
                                    <Column field='dlast_balance' header='Updated At' className="ta-center"/>
                                    <Column body={this.actionBodyTemplate} header='Actions' style={{width: '210px'}}
                                            className="ta-center"/>
                                </DataTable>
                            </div>
                            {confirmDialog}
                            {addAccountDialog}
                            {transferDialog}
                        </div>
                    </div>
                    {this.state.actionsDetailTabView && tabViewDetail}
                </div>
                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }
}
