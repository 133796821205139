import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import {catMonths, catYears, isValueText} from "../../util/commonFunctions";
import {Dropdown} from "primereact/dropdown";
import {ValidationKYC} from "../KYC/ValidationKYC";

export class Payment extends Component {
    constructor() {
        super();
        this.state = {
            invoiceNumber: '',
            listInvoice: [],
            dataTableSelection: {},

            code: '',
            merchant_key: '',
            catMonths: catMonths,
            catYears: catYears,
            expiry: '',
            focus: '',
            cardNumber: '',
            cardHolder: '',
            expiry_month: '',
            expiry_year: '',
            cvv_number: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            _state: '',
            postCode: '',
            reference: '',
            telephone: '',
            verification_code: '',
            infoPayment: {
                invoice_number: "",
                amount: "",
            },
            showSendCode: false,
            showConfirmationCode: false,
            errors: {},
        }
        this.backendService = new BackendService();
        this.loadDetailPayment = this.loadDetailPayment.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.focusHandler = this.focusHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.submitHandlerSendCodeVerification = this.submitHandlerSendCodeVerification.bind(this);
        this.submitHandlerCodeVerification = this.submitHandlerCodeVerification.bind(this);
    }

    componentDidMount() {
        let code = this.props.match.params['code'];
        let merchant_key = this.props.match.params['merchant_key'];
        if (code === undefined && merchant_key === undefined) {
            code = localStorage.getItem("code");
            merchant_key = localStorage.getItem("merchant_key");
        }

        if ((code !== undefined && merchant_key !== undefined) &&
            (code !== null && merchant_key !== null)) {
            this.loadDetailPayment(code, merchant_key);
        }
    }

    loadDetailPayment(code, merchant_key) {
        this.backendService.getPaymentInfo({code: code, merchant_key: merchant_key}).then(resp => {
            const infoPayment = resp.data
            this.setState({
                infoPayment,
                is_correct: true});
        }).catch(error => {
            if (this.growl !== null) {
                this.growl.show({severity: 'error', summary: 'Error', detail: "Invoice Payment not found"});
            }
        });
    }

    submitHandler = e => {
        e.preventDefault();
        const {
            cardNumber,
            cardHolder,
            expiry_month,
            expiry_year,
            cvv_number,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,
            reference,
        } = this.state;
        const param = {
            cardNumber,
            cardHolder,
            expiry_month,
            expiry_year,
            cvv_number,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,
            reference,
        };
        const type = "submitHandler";
        const errors = this.validateForm(param, type);

        if (Object.keys(errors).length === 0) {
            this.setState({showSendCode: true});
        } else {
            this.setState({errors});
        }
    }

    submitHandlerSendCodeVerification = e => {
        e.preventDefault();
        const {
            telephone
        } = this.state;
        const param = {telephone};
        const type = "submitHandlerSendCodeVerification";
        const errors = this.validateForm(param, type);

        if (Object.keys(errors).length === 0) {
            this.setState({showConfirmationCode: true});
        } else {
            this.setState({errors});
        }
    }

    submitHandlerCodeVerification = e => {
        e.preventDefault();
        const {
            verification_code
        } = this.state;
        const param = {verification_code};
        const type = "submitHandlerCodeVerification";
        const errors = this.validateForm(param, type);
        if (Object.keys(errors).length === 0) {

        } else {
            this.setState({errors});
        }
    }

    changeHandler = event => {
        const {errors, expiry_year, expiry_month} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        if (event.target.name === 'expiry_month') {
            const expiry = event.target.value + expiry_year;
            this.setState({expiry});
        }
        if (event.target.name === 'expiry_year') {
            const month = expiry_month === '' ? '00' : expiry_month;
            const expiry = month + event.target.value;
            this.setState({expiry});
        }
        if (event.target.name === 'cardHolder') {
            if (!isValueText(event.target.value)) {
                return;
            }
            event.target.value = event.target.value.toUpperCase()
        }

        if (event.target.name === 'cvv_number' && event.target.value.length > 3) {
            return;
        }

        this.setState({[event.target.name]: event.target.value, errors});
    };

    validateForm = (data, type) => {
        let errors = {};
        switch (type) {
            case "submitHandler":
                if (!data.cardNumber) errors.cardNumber = 'Card Number is mandatory.';
                if (!data.cardHolder) errors.cardHolder = 'Card Holder is mandatory.';
                if (!data.expiry_month) errors.expiry_month = 'Month is mandatory.';
                if (!data.expiry_year) errors.expiry_year = 'Year is mandatory.';
                if (!data.cvv_number) errors.cvv_number = 'CVV is mandatory.';
                if (!data.addressLine1) errors.addressLine1 = 'Address is mandatory.';
                if (!data.city) errors.city = 'City is mandatory.';
                if (!data._state) errors._state = 'State is mandatory.';
                if (!data.postCode) errors.postCode = 'Zipcode is mandatory.';
                break;
            case "submitHandlerSendCodeVerification":
                if (!data.telephone) errors.telephone = 'Telephone is mandatory.';
                break;
            case "submitHandlerCodeVerification":
                if (!data.verification_code) errors.verification_code = 'Verification is mandatory.';
                break;
            default:
                break
        }

        return errors;
    }

    isDisabled = e => {
        const nameState = [e.target.name] + '_error_text';
        this.setState({[nameState]: ''});

        const validateMessage = this.validateField(e.target.name, e.target.value);
        if (validateMessage !== '') {
            this.setState({[nameState]: validateMessage});
        }
    };

    focusHandler = (e) => {
        switch (e.target.name) {
            case 'cvv_number':
                this.setState({focus: 'cvc'});
                break;
            case 'card_number':
                this.setState({focus: 'number'});
                break;
            case 'holder_name':
                this.setState({focus: 'name'});
                break;
            case 'expiry_month':
            case 'expiry_year':
                this.setState({focus: 'expiry'});
                break;
            default:
                this.setState({focus: 'name'});
                break;
        }
    };

    rotatingCard = () => {
        this.setState({focus: 'name'});
    };

    render() {
        const bodyCard = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12 p-sm-12 p-md-4 p-xl-4">
                        <Cards
                            cvc={this.state.cvv_number}
                            expiry={this.state.expiry}
                            focused={this.state.focus}
                            name={this.state.cardHolder}
                            number={this.state.cardNumber}
                        />
                    </div>
                    <div className="p-col-12 p-md-6 p-md-offset-1 p-xl-6 p-xl-offset-1">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <InputText
                                    className="pd-11"
                                    placeholder="Card Number"
                                    name="cardNumber"
                                    type="number"
                                    onChange={this.changeHandler}
                                    onFocus={this.focusHandler}
                                    value={this.state.cardNumber}
                                />
                                {this.state.errors?.cardNumber !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.cardNumber}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-col-12">
                                <InputText
                                    className="pd-11"
                                    placeholder="Card Holder"
                                    name="cardHolder"
                                    onChange={this.changeHandler}
                                    onFocus={this.focusHandler}
                                    value={this.state.cardHolder}
                                />
                                {this.state.errors?.cardHolder !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.cardHolder}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-col-4">
                                <Dropdown value={this.state.expiry_month} options={this.state.catMonths}
                                          name="expiry_month"
                                          placeholder="MM"
                                          onChange={this.changeHandler}
                                          onBlur={this.isDisabled}
                                          onMouseDown={this.focusHandler}/>
                                {this.state.errors?.expiry_month !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.expiry_month}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-col-4">
                                <Dropdown value={this.state.expiry_year} options={this.state.catYears}
                                          name="expiry_year"
                                          placeholder="YYYY"
                                          onChange={this.changeHandler}
                                          onBlur={this.isDisabled}
                                          onMouseDown={this.focusHandler}/>
                                {this.state.errors?.expiry_year !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.expiry_year}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-col-4">
                                <InputText
                                    className="pd-11"
                                    placeholder="CVV"
                                    name="cvv_number"
                                    type="number"
                                    onChange={this.changeHandler}
                                    onFocus={this.focusHandler}
                                    onBlur={this.rotatingCard}
                                    value={this.state.cvv_number}
                                />
                                {this.state.errors?.cvv_number !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.cvv_number}</small>
                                    : ""
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        const billingAddress = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <h2>Billing Address</h2>
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Address</label>
                        <InputText
                            className="pd-11"
                            placeholder="Address Line 1"
                            name="addressLine1"
                            onChange={this.changeHandler}
                            value={this.state.addressLine1}
                        />
                        {this.state.errors?.addressLine1 !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.addressLine1}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Address Line 2</label>
                        <InputText
                            className="pd-11"
                            placeholder="Address Line 2"
                            name="addressLine2"
                            onChange={this.changeHandler}
                            value={this.state.addressLine2}
                        />
                        {this.state.errors?.addressLine2 !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.addressLine2}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>City</label>
                        <InputText
                            className="pd-11"
                            placeholder="City"
                            name="city"
                            onChange={this.changeHandler}
                            value={this.state.city}
                        />
                        {this.state.errors?.city !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.city}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>State</label>
                        <InputText
                            className="pd-11"
                            placeholder="State"
                            name="_state"
                            onChange={this.changeHandler}
                            value={this.state._state}
                        />
                        {this.state.errors?._state !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?._state}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Zipcode</label>
                        <InputText
                            className="pd-11"
                            placeholder="Post Code"
                            name="postCode"
                            onChange={this.changeHandler}
                            value={this.state.postCode}
                        />
                        {this.state.errors?.postCode !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.postCode}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-sm-6 p-xl-4">
                        <label>Reference</label>
                        <InputText
                            className="pd-11"
                            placeholder="Reference"
                            name="reference"
                            onChange={this.changeHandler}
                            value={this.state.reference}
                        />
                        {this.state.errors?.reference !== undefined ?
                            <small className="p-messages p-messages-error">{this.state.errors?.reference}</small>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-md-4 p-offset-4 mt-40">
                        <Button label="Submit" style={{marginBottom: '10px'}} onClick={this.submitHandler}/>
                    </div>
                </div>
            </div>
        );
        const sendCode = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <h1>Confirmation code!</h1>
                        <p><strong>Please check your cell phone</strong> to get your verification code.</p>
                        <hr/>
                    </div>
                    <div className="p-col-12 p-md-4 p-md-offset-4">
                        <InputText className="pd-11" placeholder="Telephone"
                                   name="telephone" value={this.state.telephone}
                                   type="number"
                                   onChange={this.changeHandler}/>
                        {this.state.errors?.telephone !== undefined ?
                            <nobr><small className="p-messages p-messages-error">{this.state.errors?.telephone}</small>
                            </nobr>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-md-4">
                        <Button label="Send Code" style={{marginBottom: '10px'}}
                                onClick={this.submitHandlerSendCodeVerification}/>
                    </div>
                </div>
            </div>
        );
        const validateCode = (
            <div className="p-col-12">
                <div className="p-grid">
                    <div className="p-col-12">
                        <h1>Verificarion Code!</h1>
                    </div>
                    <div className="p-col-12 p-md-4 p-md-offset-4">
                        <InputText className="pd-11" placeholder="Verification Code"
                                   name="verification_code" value={this.state.verification_code}
                                   onChange={this.changeHandler}/>
                        {this.state.errors?.verification_code !== undefined ?
                            <nobr><small
                                className="p-messages p-messages-error">{this.state.errors?.verification_code}</small>
                            </nobr>
                            : ""
                        }
                    </div>
                    <div className="p-col-12 p-md-4">
                        <Button label="Send Code" style={{marginBottom: '10px'}}
                                onClick={this.submitHandlerCodeVerification}/>
                    </div>
                </div>
            </div>
        );

        return (
            <div className="p-grid p-fluid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h1 style={{marginBottom: 0}}>Credit Card</h1>
                            </div>
                            <div className="p-col-8">
                                <div className="landscape">
                                    <img src='assets/payment_method_icons/LightColor/1.png' alt='Visa'/>
                                    <img src='assets/payment_method_icons/LightColor/2.png'
                                         alt='MasterCard'/>
                                </div>
                            </div>
                            <div className="p-col-4 ta-right">
                                <span className="label-secondary">
                                    <b>Invoice:</b> {this.state.infoPayment.invoice_number}
                                </span><br/>
                                <span className="label-secondary">
                                    <b>Amount:</b> {this.state.infoPayment.amount}
                                </span>
                            </div>
                            {!this.state.showSendCode && bodyCard}
                            {!this.state.showSendCode && billingAddress}
                            {this.state.showSendCode && !this.state.showConfirmationCode && sendCode}
                            {this.state.showConfirmationCode && validateCode}
                        </div>
                    </div>
                </div>
                <ValidationKYC history={this.props.history}/>
            </div>
        );
    }

}
