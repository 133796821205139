export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const catMonths = [{label: '01', value: '01'}, {label: '02', value: '02'}, {label: '03', value: '03'},
    {label: '04', value: '04'}, {label: '05', value: '05'}, {label: '06', value: '06'},
    {label: '07', value: '07'}, {label: '08', value: '08'}, {label: '09', value: '09'},
    {label: '10', value: '10'}, {label: '11', value: '11'}, {label: '12', value: '12'}];
export const catYears = [{label: '2022', value: '2022'}, {label: '2023', value: '2023'},
    {label: '2024', value: '2024'}, {label: '2025', value: '2025'}, {label: '2026', value: '2026'},
    {label: '2027', value: '2027'}, {label: '2028', value: '2028'}, {label: '2029', value: '2029'},
    {label: '2030', value: '2030'},];
export const countries = []
export const catCountry = []
export const accepted = "verification.accepted";
export const initialPage = "/"

function isAmount(data) {
    const re = /^[0-9]*\.?[0-9]*$/;
    let istrue = false;

    if (data === '' || re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function valueIsNumber(data) {
    const re = /^[0-9]*$/;
    let istrue = false;

    if (!isEmpty(data) && re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function skipSpace(data) {
    return data.replace(/\s/g, '');
}

function isValueText(data) {
    const re = /^[a-zA-Z ]*$/;
    let istrue = false;
    if (data === '' || re.test(data)) {
        istrue = true;
        return istrue;
    }
    return istrue;
}

function validateEmail(email) {
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validateName(name) {
    const regexName = new RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u);
    return regexName.test(name);
}

function validatePass(pass) {
    const regexPass = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{5,10}$/);
    return regexPass.test(pass);
}

function validatePhone(phone) {
    if (valueIsNumber(phone)) {
        if (phone.length > 6) {
            return true;
        }
    }
    return false;
}

function formatNumber(number) {
    return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
}

function formatCurrency(number) {
    const options = {style: 'currency', currency: 'USD'};
    const formatNumber = new Intl.NumberFormat('en-US', options);
    return formatNumber.format(number);
}

function changeStyleSheetUrl(id, value, prefix) {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
    const newURL = urlTokens.join('/');

    replaceLink(element, newURL);
}

function replaceLink(linkElement, href) {
    if (isIE()) {
        linkElement.setAttribute('href', href);
    } else {
        const id = linkElement.getAttribute('id');
        const cloneLinkElement = linkElement.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            linkElement.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }
}

function isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
}

function calculateEquivalence(exchangeRate, amount) {
    return Math.round(exchangeRate * amount * 100) / 100;
}

function calculateInverseEquivalence(exchangeRate, amount) {
    return Math.round((amount / exchangeRate) * 100) / 100;
}

function removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
        newArray.push(lookupObject[i]);
    }
    return newArray;
}

function dateToString(date) {
    if (date !== null && date !== undefined && date !== "") {
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
        return `${ye}-${mo}-${da}`;
    } else {
        return null;
    }
}
function dateToStringDDMMYY(date) {
    if (date !== null && date !== undefined && date !== "") {
        let ye = new Intl.DateTimeFormat('en', {year: 'numeric'}).format(date);
        let mo = new Intl.DateTimeFormat('en', {month: '2-digit'}).format(date);
        let da = new Intl.DateTimeFormat('en', {day: '2-digit'}).format(date);
        return `${da}-${mo}-${ye}`;
    } else {
        return null;
    }
}

function validateKYCComplete() {
    const profileData = JSON.parse(localStorage.getItem('profile'));
    if (profileData?.kyc?.porcentage < 100){
        return 'KYC';
    }
    return true;
}

function getMonths(beforeMonths) {
    const currentMonth = new Date().getMonth();
    let count = 0;
    let positionMonth;
    const returnMonths = [];

    if (beforeMonths === undefined || beforeMonths === null || beforeMonths <= 0)
        beforeMonths = 5;
    else if (beforeMonths > 12)
        beforeMonths = 11;
    else
        beforeMonths = beforeMonths - 1;

    while (beforeMonths >= count) {
        positionMonth = currentMonth - beforeMonths + count;
        if (positionMonth > 11)
            returnMonths.push(months[positionMonth - 12])
        else if (positionMonth < 0)
            returnMonths.push(months[positionMonth + 12])
        else
            returnMonths.push(months[positionMonth])
        count++;
    }
    return returnMonths;
}

function roundToTwo(num) {
    return +(Math.round(num + "e+2") + "e-2");
}

function isEmpty(data) {
    if (data === undefined || data === null || data === '')
        return true
    return false;
}

function logOut(history) {
    try {
        localStorage.clear();
        sessionStorage.clear();
        history.push("/login");
    } catch (e) {
        console.log("error: ", e)
    }
}

function savePath(pathname) {
    if (pathname.includes("/accept/payment/") && pathname.length > 20) {
        pathname = pathname.replace("/accept", '')
        localStorage.setItem('saved_path', pathname)
    }
}

async function loadProfile(self, backendService, callback) {
    const waitSeconds = 10;
    const now = new Date().getTime();
    const lastloadProfile = localStorage.getItem('lastloadProfile');
    const diff = lastloadProfile !== null ? (now - lastloadProfile) / 1000 : waitSeconds + 1;
    if (diff > waitSeconds) {
        localStorage.setItem('lastloadProfile', now);
        return await backendService.getProfile().then(resp => {
            if (resp.status === 'success') {
                let totalBalance = 0;
                let pendingBalance = 0;
                let walletName = '';
                resp.data.array_accounts.forEach(e => totalBalance += e.last_balance);
                resp.data.array_accounts.forEach(e => pendingBalance += e.pending);
                resp.data.array_accounts.forEach(e => walletName += e.label);
                localStorage.setItem('totalBalance', formatCurrency(totalBalance));
                localStorage.setItem('pendingBalance', formatCurrency(pendingBalance));
                localStorage.setItem('walletName', walletName);
                localStorage.setItem('profile', JSON.stringify(resp.data));
                const result = resp.data;
                if (callback)
                    callback(self, backendService, result, callback);
                return result;
            } else {
                return null;
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            if (error.response.status === 401) {
                logOut(self.props.history);
            }
            return null;
        });
    } else {
        return JSON.parse(localStorage.getItem('profile'));
    }
}

function changeHandler(self, event) {
    const {errors} = self.state;
    if (errors[event.target.name] !== undefined) {
        errors[event.target.name] = undefined;
    }
    if (event.target.name === 'phoneNumber' && event.target.value.length > 10) {
        return
    }

    self.setState({[event.target.name]: event.target.value, errors});
}

export const showModal = (modal, self, action) => () => {
    self.setState({[modal]: true});
    if (action !== undefined) {
        self.setState({actionToConfirm: action});
    }
}

export const hideModal = (modal, self) => () => {
    self.setState({
        [modal]: false,
        displayConfirm: false,
    });
}

export {
    isAmount,
    valueIsNumber,
    skipSpace,
    isValueText,
    validateEmail,
    validateName,
    validatePass,
    validatePhone,
    formatNumber,
    formatCurrency,
    changeStyleSheetUrl,
    replaceLink,
    calculateEquivalence,
    calculateInverseEquivalence,
    removeDuplicates,
    dateToString,
    dateToStringDDMMYY,
    validateKYCComplete,
    getMonths,
    roundToTwo,
    isEmpty,
    logOut,
    loadProfile,
    savePath,
    changeHandler,
};
