import React, {Component} from "react";
import {validateKYCComplete} from "../../util/commonFunctions";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/components/button/Button";
import {BackendService} from "../../service/wallet_backend/BackendService";

export class ValidationKYC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayConfirm: false,
            sendToPage: 'profile'
        }
        this.backendService = new BackendService();
        this.redirectToComple = this.redirectToComple.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.validateStatus = this.validateStatus.bind(this);
    }

    componentDidMount() {
        this.validateStatus();
        //const sendToPage = validateKYCComplete();
        //const displayConfirm = sendToPage === true ? false : true;
        //this.setState({displayConfirm, sendToPage});
    }
    validateStatus () {
        validateKYCComplete();
        this.backendService.getStatusKYC().then(resp => {
            if (resp.data.profile === "verification.accepted" && resp.data.wallet_status_kyc === "3") {
                const displayConfirm  = false
                this.setState({displayConfirm});
            } else {
                const displayConfirm =  true
                const sendToPage = 'KYC'
                this.setState({displayConfirm, sendToPage});
            }
        });
    }

    renderFooter() {
        return (
            <div>
                <Button label="Yes" icon="pi pi-check" onClick={() => this.redirectToComple()} autoFocus />
            </div>
        );
    }
    redirectToComple() {
        this.setState({displayConfirm: false});
        this.props.history.push(`/${this.state.sendToPage}`);
    }

    render() {
        return(
            <Dialog header={"Complete your " + this.state.sendToPage} visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={this.renderFooter()} onHide={() => this.redirectToComple()}>
                <p>You need complete your {this.state.sendToPage} before request any transaction.</p>
            </Dialog>
        );
    }

}
