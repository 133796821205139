import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {Balance} from "../MainDashboard/Balance";
import {loadProfile} from "../../util/commonFunctions";
import {Growl} from "primereact/growl";
import {Messages} from "primereact/messages";
import {ProgressSpinner} from "primereact/progressspinner";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {Dropdown} from "primereact/dropdown";
import {InputMask} from "primereact/inputmask";
import {TabView} from "primereact/tabview";
import {TabPanel} from "primereact/tabview";
import {Card} from "primereact/card";

export class Pay_vendors extends Component {
    emptyVendor = {
        name:"",
        alias:"",
        email:"",
        amount:"",
        reference:"",
        code:"",
    };
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0,
            accountid: null,
            vendors:[],
            dataTableSelection: {},
            actionsDetailTabView: false,
            chargedYet: true,
            displaynewVendor: false,
            displayPayVendor: false,
            displayPayVendorCode: false,
            displayAddVendor: false,
            displayVendor: false,
            addVendor: [],
            dataTableBestTransSelected: {},
            email: " ",
            alias: " ",
            vendor: this.emptyVendor,
            totalBalance: localStorage.getItem("totalBalance"),
            pendingBalance: localStorage.getItem("pendingBalance"),
        }

        this.backendService = new BackendService();
        this.listVendors = this.listVendors.bind(this);
        this.payTemplate = this.payTemplate.bind(this);
        this.newVendor = this.newVendor.bind(this);
        this.onHide = this.onHide.bind(this);
        this.saveVendor = this.saveVendor.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.payVendors = this.payVendors.bind(this);
        this.addExistingVendor = this.addExistingVendor.bind(this);
        this.mergeVendor = this.mergeVendor.bind(this);
        this.addVendor = this.addVendor.bind(this);
        this.listAllVendors = this.listAllVendors.bind(this);
        this.sendCodeSelectVendor = this.sendCodeSelectVendor.bind(this);
        this.payVendor = this.payVendor.bind(this);
        this.detailsTemplate = this.detailsTemplate.bind(this);
        this.detailsVendor = this.detailsVendor.bind(this);
        this.codeData = this.codeData.bind(this);
        this.statusTemplate = this.statusTemplate.bind(this);
    }

    componentDidMount() {
        const self = this;
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
                const accounts = resp.array_accounts;
                this.setState({accounts});
            }
        });
        this.listVendors();
    }


    listVendors() {
        this.backendService.listVendors().then(resp => {
            const data = resp.data;
            const vendors = data.map(vendor => {
                vendor.email = vendor.email_desc;
                return {...vendor, status_vendor_desc: 'Success'};
            });
            this.listAllVendors();
            this.setState({
                vendors
            });
        }).catch(error => {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error",
                life: 5000
            })
        });
    }

    listAllVendors() {
        this.backendService.listAllVendors().then(resp => {
            const vendors_all = resp.data;
            this.setState({
                vendors_all
            });
        }).catch(error => {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error",
                life: 5000
            })
        });
        this.backendService.listStatusVendors().then(resp => {
            const vendors_allStatus = resp.data;
            let mergedCatalogs = this.state.vendors.concat(vendors_allStatus);
            this.setState({
                vendors_allStatus,
                mergedCatalogs
            });
        }).catch(error => {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: "Error",
                life: 5000
            })
        });
    }

    payTemplate (rowData) {
        return (
            <React.Fragment>
                {rowData.status_vendor_desc === "Success" &&
                    <Button  label="Pay" onClick={() => this.payVendors(rowData)}/>
                }
            </React.Fragment>
        );
    }
    addExistingVendor (rowData) {
        return (
            <React.Fragment>
                <Button  label="Add" onClick={() => this.addVendor(rowData)}/>
            </React.Fragment>
        );
    }
    detailsTemplate (rowData) {
        return (
            <React.Fragment>
                <Button  label="Details" onClick={() => this.detailsVendor(rowData)}/>
            </React.Fragment>
        );
    }

    mergeVendor () {
        const params = {
            "bank_account_id": this.state.vendor.bank_account_id,
            "name": this.state.vendor.name,
            "alias": this.state.vendor.alias,
        };
        this.backendService.addVendor(params).then(resp => {
            if (resp["status code"] === 200 && resp.data != null) {
                this.setState({displayAddVendor: false,
                });
                this.listAllVendors();
                this.listVendors();
                this.growl.show({severity: 'success', summary: resp.message, life: 4000});
            } else {
                this.messages.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: resp.message,
                    life: 9000
                });
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            this.growl.show({severity: 'error', summary: 'Error'});
        });
    }

    sendCodeSelectVendor () {
        const profile = JSON.parse(localStorage.getItem('profile'));
        const paramsCode = {
            "email": profile.email,
            "vendor_id": this.state.vendor.id
        };
        this.setState({paramsCode})
        this.backendService.postSendEmailCode(paramsCode).then(resp => {
            if (resp["status code"] === 200) {
                this.setState({
                });
                this.growl.show({severity: 'success', summary: resp.message, life: 4000});
            } else {
                this.messages.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: resp.message,
                    life: 9000
                });
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            this.growl.show({severity: 'error', summary: 'Error'});
        });
        if (this.state.vendor.amount !== undefined && this.state.vendor.reference !== undefined) {
            const params = this.state.vendor;
            this.setState({displayPayVendor: false,
                displayPayVendorCode: true,
            params});
        } else {
            this.growl.show({
                severity: 'error',
                summary: "All data is mandatory"
            });
        }
    }


    payVendors (vendor) {
        this.setState({displayPayVendor: true,
            vendor
        });
    }
    addVendor (vendor) {
        this.setState({
            displayAddVendor: true,
            vendor
        });
    }
    detailsVendor (vendor) {
        this.setState({
            displayVendor: true,
            vendor
        });
    }
    newVendor () {
        this.setState({
            displaynewVendor: false
        })
    }
    onHide(name) {
        this.setState({
            [`${name}`]: false,
            vendor: [],
        });
    }
    changeHandler = event => {
        this.setState({accountid: event.value});
    };

    saveVendor() {
            this.setState({chargedYet: false});
            const profile = JSON.parse(localStorage.getItem('profile'));
            const accountID = profile.array_accounts[0].provider_accountid;
            const params = {
                "name": this.state.vendor.name,
                "alias": this.state.vendor.alias,
                "email": this.state.vendor.email.toLowerCase(),
                "account_id": accountID
            };
            this.backendService.newVendor(params).then(resp => {
                this.setState({chargedYet: false});
                if (resp["status code"] === 200 && resp.data != null) {
                    this.setState({
                        vendor: {
                            email: '',
                            name: '',
                            alias: ''
                        },
                        chargedYet: true,
                        activeIndex: 1,
                    });
                    this.messages.show({severity: 'success', summary: 'Please wait vendor complete to register!'});
                    this.listAllVendors();
                    this.setState({activeIndex: 1})
                } else {
                    this.messages.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: "Service Get Link KYC  doesn't work, it response null",
                        life: 9000
                    });
                }
            }).catch(error => {
                const msg = JSON.stringify(error.response.data.message);
                this.growl.show({severity: 'error', summary: 'Error', detail: msg});
                this.setState({chargedYet: true});
            });
    }
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let vendor = {...this.state.vendor};
        vendor[`${name}`] = val;
        this.setState({vendor});
    }
    codeData () {
        this.setState({chargedYet: true});
        const params = {
            "code": parseInt(this.state.vendor.code),
            "vendor_id": parseInt(this.state.paramsCode.vendor_id)
        }
        this.backendService.postCodeData(params).then(resp => {
            this.setState({chargedYet: false});
            if (resp["status code"] === 200) {
                this.payVendor();
                this.messages.show({severity: 'success', summary: 'Code match!'});
            } else {
                this.messages.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Code not match",
                    life: 9000
                });
                this.setState({chargedYet: true})
            }
        }).catch(error => {
            const msg = JSON.stringify(error.response.data.message);
            this.growl.show({severity: 'error', summary: 'Error', detail: msg});
        });
        this.setState({chargedYet: false});
    }
    payVendor () {
        this.setState({chargedYet: false});
        const params = {
        "bank_account_id": this.state.params.bank_account_id,
        "account_id": this.state.accountid.provider_accountid,
        "amount": parseInt(this.state.params.amount),
        "reference": this.state.params.reference,
    };
    this.backendService.payVendor(params).then(resp => {
        if (resp["status code"] === 200) {
            this.setState({displayPayVendor: false,
                displayPayVendorCode: false,
            });
            this.messages.show({severity: 'success', summary: "Successful payment"});
        } else {
            this.messages.show({
                severity: 'error',
                summary: 'Error',
                detail: resp.msg,
                life: 9000
            });
        }
    }).catch(error => {
        console.log("ERROR: ", error);
        this.growl.show({severity: 'error', summary: 'Error'});
    });
    this.setState({accountid: null, chargedYet: true});
    }

    statusTemplate = (rowData) => {
        let color = rowData.status_vendor_desc === "Success" ? "#228B22" :
            rowData.status_vendor_desc === "Pending" ? "#87CEFA" :
                "#ec5252";
        return (
            <div style={{
                border: `1px solid ${color}`,
                backgroundColor: color,
                padding: '5px',
                borderRadius: '5px',
                display: 'inline-block'
            }}>
            <span>
                {rowData.status_vendor_desc}
            </span>
            </div>
        );
    }



    render() {
        const newVendorDialogFooter = (
            <React.Fragment>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveVendor}/>
            </React.Fragment>
        );
        const payVendorDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => this.onHide('displayPayVendor')}/>
                <Button label="Send Code" icon="pi pi-check" className="p-button-text" onClick={this.sendCodeSelectVendor}/>
            </React.Fragment>
        );
        const payVendorCodeDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => this.onHide('displayPayVendorCode')}/>
                <Button label="Pay" icon="pi pi-check" className="p-button-text" onClick={this.codeData}/>
            </React.Fragment>
        );
        const addVendorDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={() => this.onHide('displayPayVendor')}/>
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.mergeVendor}/>
            </React.Fragment>
        );
        /*const newVendor = (
            <Dialog header="New Vendor" visible={this.state.displaynewVendor} style={{width: '580px',height: '290px', background: "#1c2155"}}
                    contentStyle={{height: 250}} footer={newVendorDialogFooter}
                     modal className="p-fluid" onHide={() => this.onHide('displaynewVendor')}>
                <div>
                    <div className="grid">
                        {this.state.chargedYet ? '' :
                            <div className="col-9 ta-right" style={{position: "relative", marginTop: -20}}>
                                <ProgressSpinner style={{width: '50px', height: '60px'}}
                                                 animationDuration=".7s"/>
                            </div>
                        }
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Email:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.email}
                                placeholder="Email"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'email')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                           <label  className="p-col-fixed"
                             style={{width: '160px'}}>Name:</label>
                           <div className="p-col">
                               <InputText
                                   value={this.state.vendor.name}
                                   placeholder="Name"
                                   onChange={(e) => this.onInputChange(e, 'name')}
                                   style={{padding: 9}}
                               />
                           </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Alias:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.alias}
                                placeholder="Alias"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );*/
        const addVendor = (
            <Dialog header="Add Vendor" visible={this.state.displayAddVendor} style={{width: '540px',height: '290px', background: "#1c2155"}}
                    contentStyle={{height: 250}} footer={addVendorDialogFooter}
                    modal className="p-fluid" onHide={() => this.onHide('displayAddVendor')}>
                <div>
                    <div className="grid">
                        {this.state.chargedYet ? '' :
                            <div className="col-9 ta-right" style={{position: "relative", marginTop: -20}}>
                                <ProgressSpinner style={{width: '50px', height: '60px'}}
                                                 animationDuration=".7s"/>
                            </div>
                        }
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Name:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.name}
                                placeholder="Name"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'name')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Alias:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.alias}
                                placeholder="Alias"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );
        const detailsVendor = (
            <Dialog header="Details of Vendor" visible={this.state.displayVendor} style={{width: '520px',height: '310px', background: "#1c2155"}}
                    contentStyle={{height: 250}}
                    modal className="p-fluid" onHide={() => this.onHide('displayVendor')}>
                <div>
                    <div className="grid">
                        {this.state.chargedYet ? '' :
                            <div className="col-9 ta-right" style={{position: "relative", marginTop: -20}}>
                                <ProgressSpinner style={{width: '50px', height: '60px'}}
                                                 animationDuration=".7s"/>
                            </div>
                        }
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Name:</label>
                        <div className="p-col">
                            <InputText
                                disabled
                                value={this.state.vendor.name}
                                placeholder="Name"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Alias:</label>
                        <div className="p-col">
                            <InputText
                                disabled
                                value={this.state.vendor.alias}
                                placeholder="Alias"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Email:</label>
                        <div className="p-col">
                            <InputText
                                disabled
                                value={this.state.vendor.email}
                                placeholder="Email"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'name')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Created:</label>
                        <div className="p-col">
                            <InputText
                                disabled
                                value={this.state.vendor.d_created}
                                placeholder="Alias"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label className="p-col-fixed" style={{width: '160px'}}>Status:</label>
                        <div className="p-col">
                            <InputText
                                disabled
                                value={this.state.vendor.status_vendor_desc}
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'alias')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const payVendor = (
            <Dialog header="Pay Vendor" visible={this.state.displayPayVendor}
                    style={{width: '580px', height: '370px', background: "#1c2155"}}
                    contentStyle={{height: 250}} footer={payVendorDialogFooter}
                    modal className="p-fluid" onHide={() => this.onHide('displayPayVendor')}>
                <div>
                    <div className="p-field p-grid p-fluid" style={{padding: '6px'}}>
                        <label className="p-col-fixed" style={{width: '160px'}}>Wallet Name:</label>
                        <div className="p-col" >
                            <Dropdown options={this.state.accounts}
                                      placeholder="Wallet Name"
                                      optionLabel="label"
                                      name="label"
                                      value={this.state.accountid}
                                      onChange={this.changeHandler}
                                      disabled={false}
                                      autoWidth={false} />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid" style={{padding: '6px'}}>
                        <label  className="p-col-fixed"
                               style={{width: '160px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.amount}
                                placeholder="$"
                                onChange={(e) => this.onInputChange(e, 'amount')}
                                style={{padding: 9}}
                            />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid" style={{padding: '6px'}}>
                        <label className="p-col-fixed" style={{width: '160px'}}>Reference:</label>
                        <div className="p-col">
                            <InputText
                                value={this.state.vendor.reference}
                                placeholder="Reference"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'reference')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const payVendorCode = (
            <Dialog header="Confirm Pay Vendor" visible={this.state.displayPayVendorCode} style={{height: '270px', background: "#1c2155"}}
                    contentStyle={{height: 250}} footer={payVendorCodeDialogFooter}
                    modal className="p-fluid" onHide={() => this.onHide('displayPayVendorCode')}>
                <div>
                    <div className="p-field p-grid p-fluid" style={{marginLeft: '74px'}}>
                        <label className="p-col-fixed ta-center" >Code:</label>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <div className="p-col">
                            <InputMask
                                className="text-center"
                                tooltip="Enter code sent to your email"
                                tooltipOptions={{ className: 'yellow-tooltip', position: 'bottom' }}
                                mask="9999"
                                value={this.state.vendor.code}
                                placeholder="99-99"
                                style={{padding: 9}}
                                onChange={(e) => this.onInputChange(e, 'code')}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const tabViewDetail = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-12 mt-20">
                        <DataTable value={this.state.vendors_all} selectionMode="single" paginator={true}
                                   rows={20} className="mt-20" responsive={true}
                                   selection={this.state.dataTableBestTransSelected}
                                   onSelectionChange={event => this.setState({dataTableBestTransSelected: event.value})} >
                            <Column field='email_desc' header='Email' filter={true}/>
                            <Column field='id' header='Id' className="ta-center" filter={true} style={{display: "none"}}/>
                            <Column field='name' header='Alias' className="ta-center" filter={true}/>
                            <Column field='d_created' header='Created' className="ta-center" filter={true}/>
                            <Column  body={this.addExistingVendor}  className="ta-center"/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );

        /*const tabViewDetail2 = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-10">
                        <h2>Status Vendors</h2>
                    </div>
                    <div className="p-col-12 mt-20">
                        <DataTable value={this.state.vendors_allStatus} selectionMode="single" paginator={true}
                                   rows={5} className="mt-20" responsive={true}
                                   selection={this.state.dataTableBestTransSelected}
                                   onSelectionChange={event => this.setState({dataTableBestTransSelected: event.value})}>
                            <Column field='email' header='Email' filter={true}/>
                            <Column  field='name' header='Alias' filter={true} className="ta-center"/>
                            <Column  field='status_vendor_desc' header='Status' className="ta-center" filter={true}/>
                            <Column  body={this.detailsTemplate}  className="ta-center"/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );*/
        const tabViewDetail3 = (
            <div className="card card-w-title ">
                <div className="p-grid">
                    <div className="p-col-12 mt-20">
                        <DataTable value={this.state.mergedCatalogs} selectionMode="single" paginator={true} rows={10}
                                   selection={this.state.dataTableSelection}
                                   onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                   responsive={true}>
                            <Column field='alias' header='Alias' filter={true}/>
                            <Column field='name' header='Name' className="ta-center" filter={true}/>
                            <Column field='email' header='Email' className="ta-center" filter={true}/>
                            <Column field='d_created' header='Created' className="ta-center" filter={true}/>
                            <Column body={this.statusTemplate} style={{width:'180px'}} header='Status' className="ta-center" filter={true}/>
                            <Column  body={this.payTemplate} style={{width:'60px'}}  className="ta-center"/>
                            <Column  body={this.detailsTemplate} style={{width:'250px'}} className="ta-center" />
                        </DataTable>
                    </div>
                </div>
            </div>
        );
        const tabNewVendor = (
            <div className="card card-w-title" style={{height: '100vh', width: '81vw'}}>
                <div className="p-grid">
                    <div className="p-col-12 p-md-6">
                        <div className="card card-w-title" style={{height: '40%',width: '40%',position: 'absolute',
                            top: '50%',
                            left: '53%',
                            transform: 'translate(-50%, -50%)'}}>
                            <Card
                            header="New Vendor"
                            contentStyle={{height: 350}} footer={newVendorDialogFooter}
                            modal className="p-fluid">
                            <div>
                                <div className="grid">
                                    {this.state.chargedYet ? '' :
                                        <div className="col-9 ta-right"
                                             style={{position: "relative", marginTop: -20}}>
                                            <ProgressSpinner style={{width: '50px', height: '60px'}}
                                                             animationDuration=".7s"/>
                                        </div>
                                    }
                                </div>
                                <div className="p-field p-grid p-fluid">
                                    <label className="p-col-fixed" style={{width: '90px'}}>Email:</label>
                                    <div className="p-col">
                                        <InputText
                                            value={this.state.vendor.email}
                                            placeholder="Email"
                                            style={{padding: 9}}
                                            onChange={(e) => this.onInputChange(e, 'email')}
                                        />
                                    </div>
                                </div>
                                <div className="p-field p-grid p-fluid">
                                    <label className="p-col-fixed"
                                           style={{width: '90px'}}>Name:</label>
                                    <div className="p-col">
                                        <InputText
                                            value={this.state.vendor.name}
                                            placeholder="Name"
                                            onChange={(e) => this.onInputChange(e, 'name')}
                                            style={{padding: 9}}
                                        />
                                    </div>
                                </div>
                                <div className="p-field p-grid p-fluid">
                                    <label className="p-col-fixed" style={{width: '90px'}}>Alias:</label>
                                    <div className="p-col">
                                        <InputText
                                            value={this.state.vendor.alias}
                                            placeholder="Alias"
                                            style={{padding: 9}}
                                            onChange={(e) => this.onInputChange(e, 'alias')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>
                        </div>
                    </div>
                </div>
            </div>
        );


        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12" >
                    <div className="card card-w-title datatable-demo" >
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8 p-md-8">
                                <h2 className="mb-20">My Vendors</h2>
                            </div>
                            <div className="p-col-12 p-lg-4 p-md-4 ta-right">
                                <Balance totalBalance={this.state.totalBalance} pendingBalance={this.state.pendingBalance}/>
                            </div>
                            <div className="card">
                                <div className="p-col-12 text-right">
                                    <Messages ref={(el) => this.messages = el}></Messages>
                                </div>
                                <TabView>
                                    <TabPanel header="All Vendors">
                                        {tabViewDetail}
                                    </TabPanel>
                                    <TabPanel header="Search Vendors">
                                        {tabViewDetail3}
                                    </TabPanel>
                                    <TabPanel header="Create New Vendor">
                                        {tabNewVendor}
                                    </TabPanel>
                                </TabView>
                            </div>
                        </div>
                    </div>
                </div>
                {payVendor}
                {addVendor}
                {detailsVendor}
                {payVendorCode}
                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }
}


