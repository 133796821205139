import React, {Component} from "react";
import PropTypes from "prop-types";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export class Balance extends Component {
    static defaultProps = {
        totalBalance: 0,
        pendingBalance: 0,
    }
    static propTypes = {
        totalBalance: PropTypes.string,
        pendingBalance: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.state = {
            arrayAccounts: []
        }
    }

    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        const arrayAccounts = profile.array_accounts;
        this.setState({arrayAccounts})
    }




    render() {
        const formatCurrency = (field) => {
            return (rowData) => parseFloat(rowData[field]).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
        }
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <DataTable value={this.state.arrayAccounts}>
                        <Column className="ta-center" field="label" header="Wallet Name"/>
                        <Column className="ta-center" body={formatCurrency('last_balance')} field="last_balance" header="Available Balance"/>
                        <Column className="ta-center" body={formatCurrency('pending')}field="pending" header="Pending Balance"/>
                    </DataTable>
                </div>
            </div>
        );
    }
}
