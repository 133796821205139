import React, {Component} from "react";
import {changeStyleSheetUrl} from "../../util/commonFunctions";
import {Growl} from "primereact/growl";
import {Button} from "primereact/button";

export class Bank_Account_Accepted extends Component {

    constructor() {
        super();
        this.close = this.close.bind(this);
    }
    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    close = e => {
        e.preventDefault();
        window.open("about:blank", "_self");
        window.close();
    }

    render() {
        return (
            <div>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="main">
                    <section className="signup">
                        <div className="container">
                            <div className="signup-content card">
                                <div className="signup-form">
                                    <div className="p-col-12 ta-center">
                                        <h1 className="form-title">Bank Account</h1>
                                    </div>
                                    <div className="p-col-12 ta-center">
                                        <h2>Bank account accepted</h2>
                                        <h4>Close window and login in Gostax</h4>
                                    </div>
                                    <div className="p-col-12 ta-center">
                                        <Button label="Close" style={{marginBottom: '10px'}}
                                                onClick={this.close}/>
                                    </div>
                                </div>
                                <div className="signup-image">
                                    <figure><img src="assets/ewallet/img/gostax_logo.png" alt="singImage" style={{height: 100, marginTop: 60}}/>
                                    </figure>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        )
    }
}
