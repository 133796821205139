import React, {Component} from 'react';
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Dropdown} from "primereact/dropdown";
import {ValidationKYC} from "../KYC/ValidationKYC";

class AddWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: true,
            displayConfirm: false,
            actionToConfirm: null,
            processService: false,
            password: '',
            newPassword: '',
            confirmPassword: '',
            updated: false,
            errors: {},
            selectedTrustOption: null,
            selectedBusinessOption: null
        };
        this.trustWalletOptions = [
            {
                label: 'Asset Protection',
                value: process.env.PUBLIC_URL + '/asset_protection_trust.html'
            },
            {
                label: 'Business/Sole Corp.',
                value: process.env.PUBLIC_URL + '/business_trust_and_corp_sole_business_trust.html'
            }
        ];

        this.businessWalletOptions = [
            {
                label: 'Business',
                value: process.env.PUBLIC_URL + '/business_account_with_ecommerce .html'

            },
            {
                label: 'Marketplace',
                value: process.env.PUBLIC_URL + '/marketplace_account_full_suite_of_business_services.html'
            }
        ];
        this.backendService = new BackendService();
        this.submitHandler = this.submitHandler.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
    }

    confirmationDialog = (showModal, action) => {
        const params = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        };
        const errors = this.validate(params);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            });
        } else {
            this.setState({errors});
        }
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }

    submitHandler = () => {
        const {
            password,
            newPassword,
            confirmPassword
        } = this.state;
        const errors = {};
        this.setState({updated: true});
        if(newPassword === confirmPassword){
            const params = {
                old_password: password,
                new_password1: newPassword,
                new_password2: confirmPassword
            };
            this.backendService.postChangePassword(params).then(resp => {
                if(resp["status code"] === 200){
                    this.growl.show({severity: 'success', summary: 'Success', detail: "Your password was updated"});
                    this.setState({
                        password: '',
                        newPassword: '',
                        confirmPassword: ''
                    })
                }
            }).catch(error => {
                this.setState({updated: false});
                if(error.response?.data["status code"] === 409){
                    errors.serviceError = error.response.data.message?.password_mismatch + "\n" + error.response.data.message?.password_incorrect;
                    this.setState({errors})
                    this.growl.show({severity: 'error', summary: 'Error on service', detail: errors.serviceError});
                } else {
                    this.growl.show({severity: 'error', summary: 'Error on service', detail: error.message});
                }
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({updated: false});
            errors.newPassword = 'New password is not equals to confirm.';
            errors.confirmPassword = 'Confirm password is not equals to new password.';
        }
        this.setState({errors, displayConfirm: false});
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    }

    validate = (data) => {
        let errors = {};
        if (!data.password) {
            errors.password = 'Password is mandatory.';
        }
        if (!data.newPassword) {
            errors.newPassword = 'New password is mandatory.';
        }
        if (!data.confirmPassword) {
            errors.confirmPassword = 'Confirm password is mandatory.';
        }
        return errors;
    }

    render() {


        return (
            <div className="p-col-12">
                <div className="card" style={{padding: 40}}>
                    <div className="p-grid p-field">
                        <div className="p-col-12">
                            <h1>Add New Wallet</h1>
                        </div>
                        <div className="p-col-6">
                            <div className="p-fluid p-grid p-formgrid p-formgroup-inline">
                                <div className="p-col-12 p-sm-6 p-xl-8">
                                    <h2>Trust Wallet</h2>
                                    <Dropdown
                                        placeholder="Select a wallet type"
                                        options={this.trustWalletOptions}
                                        value={this.state.selectedTrustOption}
                                        onChange={e => {
                                            this.setState({selectedTrustOption: e.value}, () => {
                                                window.open(this.state.selectedTrustOption, '_blank');
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <div className="p-fluid p-grid p-formgrid p-formgroup-inline">
                                <div className="p-col-12 p-sm-6 p-xl-8">
                                    <h2>Business Wallet</h2>
                                    <Dropdown
                                        placeholder="Select a wallet type"
                                        options={this.businessWalletOptions}
                                        value={this.state.selectedBusinessOption}
                                        onChange={e => {
                                            this.setState({selectedBusinessOption: e.value}, () => {
                                                window.open(this.state.selectedBusinessOption, '_blank');
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ValidationKYC history={this.props.history}/>
            </div>
        );
    }
}

export default AddWallet;
