import React, {Component} from 'react';

export class Help extends Component {
    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12 card_email">
                    <div className="card-row-container">
                        <div className="card-row-header">
                            <div>
                                <div className="card-col-100">
                                    <a href="https://c2cwallet.web.snbxt.com/">
                                        <img align="center" border="0"
                                             src="https://c2cwallet.web.snbxt.com/assets/ewallet/img/logo_c2c.png"
                                             alt="c2c" title="Logo"
                                             width="185.6"
                                             className="v-src-width v-src-max-width"/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-row-container">
                        <div className="card-row-header">
                            <div className="card-col-100 card-body">
                                <h1 className="title"><strong>Forgot you password?</strong></h1>
                            </div>
                        </div>
                    </div>

                    <div className="card-row-body">
                        <div className="card-row-header">
                            <div className="card-col-100 card-body">
                                <img align="center" border="0"
                                     src="https://c2cwallet.web.snbxt.com/assets/images/data_security_20.jpg"
                                     alt="Hero Img" title="Hero Img"
                                     width="295.8"
                                     className="v-src-width v-src-max-width"/>

                                <h3 className="email-messages">
                                    Hi, firstname lastname <br/>
                                    There was a request to change your password! <br/>
                                    <span>If did not make this request, just ignore this email. Otherwise, please click the
                                        button below to change the password:</span>
                                </h3>

                                <div align="center">
                                    <a href="http://expresswalletc2c.services.snbxt.com/forgot/MTU/63g-d89d5f5a848673d940a8/"
                                       className="email-button">
                                        <span>Reset password</span>
                                    </a>
                                </div>
                                <div align="center">
                                    <p className="email-messages">
                                        <span>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.&nbsp;
                                        </span>
                                    </p>
                                </div>
                                <div align="left">
                                    <p><span>If you have any questions. Please feel free to inform - We're always ready to help out.</span></p>
                                    <p><span>Cheers,</span></p>
                                    <p><strong><span>The Team - C2C.</span></strong></p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-row-footer">
                        <div className="card-col-100 card-body">
                            <h1><strong>Get In Touch</strong></h1>
                            <div className="divider"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
