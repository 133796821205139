import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {isEmpty, loadProfile, logOut} from "../../util/commonFunctions";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {Messages} from "primereact/messages";
import {SplitButton} from "primereact/splitbutton";
import {MERCHANT_KEY} from "../../util/constants";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Growl} from "primereact/growl";

export class BankAccounts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profileData: null,
            banks: [],
            dataTableSelection: {},
            LinkBankAccount: null,
            displayConfirm: false,
            displayConfirmSend: false,
            linkBank: '',
            confirmDeleteDisplay: false,
            withdrawalDisplay: false,
            actionToConfirm: null,
            accounts: [],
            sender_account: '',
            sender_amount: '',
            errors: {},
            processService: false,
            loadProfileReady: false
        };
        this.actions = [
            {
                label: 'Withdrawal',
                command: () => {
                    this.setState({withdrawalDisplay: true});
                }
            },
            {
                label: 'Delete',
                command: () => {
                    this.setState({confirmDeleteDisplay: true});
                }
            }
        ];
        this.backendService = new BackendService();
        this.loadListBanks = this.loadListBanks.bind(this);
        this.getLinkBankAccount = this.getLinkBankAccount.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.deleteConfirm = this.deleteConfirm.bind(this);
        this.openConfirmPath = this.openConfirmPath.bind(this);
        this.withdrawalConfirm = this.withdrawalConfirm.bind(this);
        this.loadAccounts = this.loadAccounts.bind(this);
    }

    componentDidMount() {
        const self = this;
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
                this.setState({
                    loadProfileReady: true
                });
                self.loadListBanks();
                self.loadAccounts(resp, self);
            }
        });
    }



    loadListBanks() {
        const self = this;
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const banks=[];
        if (profileData?.accounts?.USD?.provider_accountid !== null && profileData?.accounts?.USD?.provider_accountid !== undefined) {
            self.backendService.getListBanks(profileData.accounts?.USD?.provider_accountid).then(resp => {
                if (resp["status code"] === 200) {
                    if (resp.data) {
                        resp.data.forEach(element => {
                            element.VerifiedDate = element.VerifiedDate.substring(0, 10);
                            banks.push(element)
                        });
                    }
                    self.messages.show({severity: 'success', summary: 'Success', detail: `Load ${banks.length} banks`});
                    self.setState({banks, profileData});
                }
            }).catch(error => {
                try {
                    if (error.response.status === 401) {
                        logOut(self.props.history);
                    } else if (self.messages !== null) {
                        self.messages.show({severity: 'error', summary: 'Error', detail: `Error load profile`});
                    }
                } catch (e) {
                    console.error("Error:", e)
                }
            });
        }
    }
    getLinkBankAccount(){
        const {profileData} = this.state;
        this.setState({displayConfirmSend: true});
        if(profileData !== null){
            this.backendService.getLinkBankAccount(profileData.accounts?.USD?.provider_accountid).then(resp => {
                if(resp["status code"] === 200 && resp.data != null){
                    this.setState({linkBank:resp.data});
                    window.open(resp.data, "_blank",  "width=800,height=600, toolbar=no, location=no,top=70,left=100")
                } else {
                    this.messages.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: "Service Get Link Bank Account doesn't work, it response null",
                        life: 9000
                    });
                    this.setState({displayConfirm: false})
                }
            }).catch(error => {
                console.log("ERROR: ", error);
                this.messages.show({severity: 'error', summary: 'Error', detail: "Error get profile"});
            });
        }

    }

    loadAccounts = (resp, self) => {
        const accounts = resp.array_accounts;
        accounts.forEach(element => {
            element.label = element.label + " / $" + element.last_balance;
        });
        const sender_account = accounts.length > 0 ? accounts[0] : ';'
        this.setState({accounts, sender_account});
    };

    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
            displayConfirmSend: false,
            withdrawalDisplay: false,
        });
        if (!isEmpty(this.state.linkBank) && this.state.displayConfirm) {
            this.messages.show({
                severity: 'success',
                summary: 'Success',
                detail: "Reload Link Bank Accounts",
                life: 5000
            });
            this.loadListBanks();
        }

    }
    confirmationDialog = (nameModal, showModal, action) => {
        if (nameModal === "withdrawalDisplay") {
            if (this.state.sender_amount.length === 0 ||
                this.state.dataTableSelection?.BankAccountToken === '') {
                this.growl.show({severity: 'info', summary: 'info', detail: 'All fields are mandatory', life: 4500});
                return;
            }
        }
        this.setState({
            [showModal]: true,
            actionToConfirm: action,
            displayConfirmSend: false,
        })
    }
    showModal = (modal, rowData) => () => {
        this.setState({[modal]: true});
        if (rowData !== undefined) {
            this.setState({dataTableSelection: rowData});
        }
    }
    deleteConfirm() {
        this.setState({displayConfirmSend: true});
        const profileData = JSON.parse(localStorage.getItem('profile'));
        const params = {
            account_id: profileData.accounts?.USD?.provider_accountid,
            bank_account_token: this.state.dataTableSelection.BankAccountToken
        }

        this.backendService.deleteLinkBank(params).then(resp => {
            if (resp.status === 'success') {
                this.setState({confirmDeleteDisplay: false});
                this.messages.show({severity: 'success', summary: 'Success', detail: `Card deleted`, life: 3500});
                this.loadListBanks();
            }
            this.setState({displayConfirmSend: false});
        }).catch(error => {
            console.log("error: ", error);
            if(error.response.status === 401){
                logOut(this.props.history);
            } else if (this.messages !== null)
                this.messages.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "User or password is wrong"
            });
        });
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <SplitButton
                    label="Withdrawal"
                    className="ta-left"
                    onClick={this.showModal('withdrawalDisplay', rowData)}
                    model={this.actions}></SplitButton>
            </React.Fragment>
        )
    }

    openConfirmPath() {
        const self = this;
        const ventana = window.open(this.state.linkBank, "_ linkBankAccount",  "width=800,height=750, toolbar=no, location=no,top=70,left=100");
        let time= 0;
        let interval = setInterval(function(){
            //Comprobamos que la ventana no este cerrada
            if(ventana.closed !== false) {
                //Si la ventana ha sido cerrada, limpiamos el contador
                window.clearInterval(interval)
                console.log(`Tiempo total: ${time} s `)
                self.setState({displayConfirm: false, linkBank: ''});
                self.loadListBanks();
                setTimeout(() => {
                    if(self.state.banks.length > 0){
                        self.props.history.push("/quick_checkout");
                    }
                }, 3000);
            } else {
                //Mientras no se cierra la ventana sumamos los segundos
                time +=1;
            }
        },1000)


    }

    withdrawalConfirm = () => {
        this.setState({processService: true});
        const profileData = JSON.parse(localStorage.getItem('profile'));
        this.growl.clear();
        const params = {
            merchant_key: MERCHANT_KEY,
            username: profileData.email,
            sender_account: this.state.sender_account.provider_accountid,
            receiver_bank: this.state.dataTableSelection?.BankAccountToken,
            amount: this.state.sender_amount,
            currency: "USD",
            test: true
        }

        this.backendService.transferWalletToAccount(params).then(resp => {
            if(resp.status === "Success"){
                this.messages.show({severity: 'success', summary: 'Success', detail: 'Transfer success'});
                this.setState({
                    displayConfirm: false,
                    displayConfirmSend: false,
                    withdrawalDisplay: false,
                    processService: false,
                    sender_amount: ''
                });
            }
        }).catch(error => {
            console.log("ERROR: ", error);
            this.messages.show({severity: 'error', summary: 'Error', detail: "Try transfer later"});
        })
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel" disabled={this.state.displayConfirmSend}
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.displayConfirmSend}/>
            </div>
        );
        const confirmDialog = (
            <Dialog header={this.state.linkBank === '' ? "Add Bank Account?" : 'Continue'}
                    visible={this.state.displayConfirm} modal={true}
                    style={{width: '350px', background: "#1c2155"}}
                    footer={this.state.linkBank === '' ? renderFooterConfirm('displayConfirm', this.state.actionToConfirm) : ''}
                    onHide={this.hideModal('displayConfirm')}>
                {this.state.linkBank === '' ?
                    <p>Yes, continue</p>
                    :
                    <div className="p-grid p-fluid">
                        <div className="p-col-6 p-offset-3">
                            <Button label="GO NOW"
                                    onClick={this.openConfirmPath}/>
                        </div>
                    </div>
                }
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                {nameModal !== 'infoCardDisplay' &&
                    <Button icon="pi pi-check"
                            onClick={(e) => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                            disabled={this.state.processService}
                            label={textAction}/>
                }
            </div>
        )

        const confirmDeleteDialog = (
            <Dialog header="Are you sure?"
                    visible={this.state.confirmDeleteDisplay} modal={true}
                    style={{width: '400px', background: "#1c2155"}}
                    contentStyle={{height: 80}}
                    footer={renderFooterConfirm('confirmDeleteDisplay', this.deleteConfirm)}
                    onHide={this.hideModal('confirmDeleteDisplay')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const withdrawalDialog = (
            <Dialog header="Withdrawal" visible={this.state.withdrawalDisplay} modal={true}
                    style={{width: '400px', background: "#1c2155"}}
                    contentStyle={{height: 165}}
                    footer={dialogFooter('withdrawalDisplay', this.withdrawalConfirm, 'Send')}
                    onHide={this.hideModal('withdrawalDisplay')}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="account" className="p-col-fixed mt-6" style={{width:'100px'}}>Account:</label>
                        <div className="p-col">
                            <Dropdown
                                scrollHeight="100px"
                                options={this.state.accounts}
                                optionLabel="label"
                                value={this.state.sender_account}
                                placeholder="Select Account"
                                name="sender_account"
                                onChange={ this.changeHandler} autoWidth={false}/>
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amount" className="p-col-fixed mt-6" style={{width:'100px'}}>Amount:</label>
                        <div className="p-col">
                            <InputText
                                className="pd-11"
                                placeholder="Amount"
                                name="sender_amount"
                                onChange={this.changeHandler}
                                value={this.state.sender_amount}
                                type="number"
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-6">
                                <h2 className="mb-20">My Bank Accounts</h2>
                            </div>
                            <div className="p-col-6 text-right">
                                <Button icon="pi pi-refresh" tooltip="Reload"
                                        tooltipOptions={{position: 'bottom', mouseTrack: true, mouseTrackTop: 15}}
                                        onClick={() => this.loadListBanks()}/>
                                <Button label="Add Bank Account" onClick={this.getLinkBankAccount}/>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <Messages ref={(el) => this.messages = el} />
                        </div>
                        <div className="p-col-12">
                            <DataTable value={this.state.banks} selectionMode="single" paginator={true} rows={10}
                                       selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                       responsive={true}>
                                <Column field='BankAccountName' header='Name Account'/>
                                <Column field='BankAccountToken' header='Token' className="ta-center"/>
                                <Column field='VerifiedDate' header='Verified Date' className="ta-center"/>
                                <Column field='Status' header='Status' className="ta-center"/>
                                <Column body={this.actionBodyTemplate} header='Actions' style={{width:'210px'}} className="ta-center"/>
                            </DataTable>
                        </div>
                    </div>
                </div>
                {confirmDialog}
                {confirmDeleteDialog}
                {withdrawalDialog}
                {this.state.loadProfileReady && <ValidationKYC history={this.props.history}/>}
            </div>
        )
    }
}
