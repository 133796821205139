import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {changeStyleSheetUrl, initialPage, loadProfile, validateKYCComplete} from "../../util/commonFunctions";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Checkbox} from "primereact/checkbox";

export class AboutPayment extends Component {
    constructor() {
        super();
        this.state = {
            invoice: '',
            email: '',
            amount: '',
            code: '',
            merchant_key: '',
            is_correct: false,

            username: '',
            password: '',
            login: false,
            validando: false,
            checkedRemember: false,
        }
        this.backendService = new BackendService();
        this.submitHandler = this.submitHandler.bind(this);
        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.checkSession = this.checkSession.bind(this);
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');

        let code = this.props.match.params['code'];
        let merchant_key = this.props.match.params['merchant_key'];

        if (code !== undefined && merchant_key !== undefined) {
            this.backendService.getPaymentInfo({code: code, merchant_key: merchant_key}).then(resp => {
                const invoice = resp.data.invoice_number;
                const email = resp.data.email
                const amount = "$" + resp.data.amount
                this.setState({
                    invoice,
                    email,
                    amount,
                    code,
                    merchant_key,
                    is_correct: true
                });
            }).catch(error => {
                if (this.growl !== null) {
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Invoice Payment not found"});
                }
            });
        }
    }

    submitHandler = e => {
        e.preventDefault();
        //this.props.history.push(`/payment/${this.state.code}/${this.state.merchant_key}`);
        const pathname = '/invoices';
        localStorage.setItem('saved_path', pathname)
        this.props.history.push(pathname);
    }

    sendSignUp = () => {
        this.props.history.push("/signUp");
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.checkSession(e);
        }
    }
    checkSession = (e) => {
        this.setState({validando: true})
        e.preventDefault();
        if (this.isValidForm()) {
            this.validarLogueo(this);
        } else {
            this.setState({validando: false});
            let data = {severity: 'warn', summary: 'Warning', detail: ''};
            this.growl.show(data);
        }
    }

    isValidForm() {
        let isValid = true;
        let usernameError = '';
        let passwordError = '';

        usernameError = this.state.username === '' ? 'El Usuario es obligatorio' : '';
        passwordError = this.state.password === '' ? 'La Contraseña es obligatorio' : '';

        isValid = usernameError === '' && passwordError === '';
        return isValid;
    }

    validarLogueo(self) {
        this.backendService.postLogin({
            username: self.state.username, password: self.state.password
        }).then(accessResp => {
            if (accessResp.access) {
                const expiration = (Date.now() / 1000) + 1000;

                localStorage.setItem('userNameSesion', self.state.username);
                localStorage.setItem("access_token", accessResp.access);
                localStorage.setItem("expires_date", expiration.toString());
                localStorage.setItem("refresh_token", accessResp.refresh);
                localStorage.setItem('login', true);
                loadProfile(self, self.backendService, null).then(resp => {
                    if (resp !== null) {
                        let lstMerchants = JSON.stringify(resp.profile.lstMerchants);
                        let role = resp.profile.rol;
                        let phone = resp.profile.cell_number;
                        const userLogged = resp.first_name + ' ' + resp.last_name;

                        localStorage.setItem('lstMerchants', lstMerchants);
                        localStorage.setItem('role', role);
                        localStorage.setItem('phone', phone);
                        localStorage.setItem('userLogged', userLogged);
                        this.backendService.getKYCStatus()
                            .then(kycResp => {
                                if (kycResp.status === 'success') {
                                    const documents = kycResp.data;
                                    localStorage.setItem('documents', JSON.stringify(documents));
                                }
                                self.setState({login: true});
                                if (validateKYCComplete() !== true) {
                                    self.props.history.push("/kyc");
                                } else {
                                    self.props.history.push(initialPage);
                                }
                            }).catch(error => {
                            self.setState({login: true});
                            self.props.history.push(initialPage);
                            if (self.growl !== null) {
                                self.growl.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: "Error load KYC documents"
                                });
                            }
                        });
                    } else {
                        self.setState({validando: false});
                        self.growl.show({severity: 'error', summary: 'Error', detail: "Bad credentials"});
                    }
                });
            }
        }).catch(error => {
            self.setState({validando: false});
            if (self.growl !== null) {
                self.growl.show({severity: 'error', summary: 'Error', detail: "User or password is wrong"});
            }
        });
    }


    render() {
        return (
            <div>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="main">
                    <section className="signup">
                        <div className="container">
                            <div className="signup-content card">
                                <div className="signup-form">
                                    <h1 className="form-title">Detail of Payment</h1>
                                    <div className="p-grid">
                                        <div className="p-col-12 ml-40">
                                            <label>Invoice: <b>{this.state.invoice}</b></label>
                                        </div>
                                        <div className="p-col-12 ml-40">
                                            <label>Email: <b>{this.state.email}</b></label>
                                        </div>
                                        <div className="p-col-12 ml-40">
                                            <label>Amount: <b>{this.state.amount}</b></label>
                                        </div>
                                        <div className="p-col-9 ta-right p-offset-1">
                                            <a href="#/signUp" onClick={this.sendSignUp} className="signup-image-link"
                                               style={{textDecoration: "underline" /*color: "#0062cc"*/}}>Create an
                                                account</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="signup-image">
                                    <h1 className="form-title">Sign in to Pay</h1>
                                    <form method="POST" className="register-form" id="login-form">
                                        <div className="form-group">
                                            <span className="p-input-icon-left p-fluid">
                                                <i className="fa fa-user"/>
                                                <InputText value={this.state.username} placeholder="email"
                                                           onKeyPress={this._handleKeyPress}
                                                           onChange={(e) => this.setState({username: e.target.value})}/>
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <span className="p-input-icon-left p-fluid">
                                                <i className="fa fa-lock"/>
                                                <InputText value={this.state.password} placeholder="Password"
                                                           onKeyPress={this._handleKeyPress} type="password"
                                                           onChange={(e) => this.setState({password: e.target.value})}/>
                                            </span>
                                        </div>
                                        <div className="form-group">
                                            <Checkbox inputId="binary" checked={this.state.checkedRemember}
                                                      onChange={e => this.setState({checkedRemember: e.checked})}/>
                                            <label htmlFor="remember-me" style={{color: "#E0C46C"}}
                                                   className="label-agree-term"><span><span></span></span>Remember
                                                me</label>
                                        </div>
                                        <div className="p-grid">
                                            <div className="p-col-6">
                                                <a href="/#/forgot_password">Forgot Password?</a>
                                            </div>
                                            <div className="p-col-6 ta-right">
                                                <Button label="Sign In" style={{padding: 12}}
                                                        className={this.state.validando ? 'secondary-btn' : "p-button-primary"}
                                                        disabled={this.state.validando} onClick={this.checkSession}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }

}
