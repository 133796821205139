import React, {Component} from 'react';
import {Button} from "primereact/button";
import {BackendService} from "../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import "./whiteStyle.css"

import {Checkbox} from "primereact/checkbox";
import {changeStyleSheetUrl, validateEmail} from "../util/commonFunctions";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            email: '',
            password: '',
            confirmPassword: '',
            acceptedTermConditios: false,
            errors: {},
            processing:false,
            displayBasic2: false,
            displayMaximizable: false
        };

        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.submitHandler = this.submitHandler.bind(this);
        this.showPassword1 = this.showPassword1.bind(this);
        this.showPassword2 = this.showPassword2.bind(this);
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    onClick(name, position) {
        let state = {
            [`${name}`]: true
        };

        if (position) {
            state = {
                ...state,
                position
            }
        }

        this.setState(state);
    }
    onHide(name) {
        this.setState({
            [`${name}`]: false
        });
    }
    changeHandler = event => {
        const {errors} = this.state;
        if(errors[event.target.name] !== undefined){
            errors[event.target.name] = undefined;
        }
        if( event.target.name === "acceptedTermConditios")
            this.setState({acceptedTermConditios: event.checked})
        else
            this.setState({[event.target.name]: event.target.value});

        this.setState({errors});
    };

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.submitHandler(e);
        }
    }

    submitHandler = event => {
        event.preventDefault();
        const self = this;
        const data = {
            firstName: this.state.firstName,
            email: this.state.email.toLowerCase(),
            password: this.state.password,
            confirmPassword: this.state.confirmPassword,
            acceptedTermConditios: this.state.acceptedTermConditios
        }
        const errors = this.validate(data);
        if(Object.keys(errors).length === 0){
            this.setState({processing:true});

            const backendService = new BackendService();
            const first_name = this.state.firstName.indexOf(" ");
            backendService
                .postSignup({
                    email: this.state.email.toLowerCase(),
                    password: this.state.password,
                    username: this.state.email.toLowerCase(),
                    first_name: this.state.firstName.substring(0,first_name),
                    last_name: this.state.firstName.substring(first_name+1, this.state.firstName.length),
                })
                .then(resp => {
                    if (resp.status === 'success') {
                        this.growl.show({
                            severity: 'success',
                            summary: 'Verify',
                            detail: 'Verify your email to complete your signup',
                            sticky:true
                        });
                        setTimeout(function () {
                            self.props.history.push('/login');
                        }, 3000);
                    } else {
                        this.growl.show({
                            severity: 'info',
                            summary: 'info',
                            detail: 'Error in server we working on it'
                        });
                    }
                }).catch(error => {
                    const msg = error.response.data?.username !== undefined ? error.response.data?.username : "Error in server we working on it";
                    this.growl.show({severity: 'info', summary: 'info', detail: msg});
                    console.log('error: ' + error);
                    this.setState({processing:false});
                });
        } else {
            this.setState({errors});
        }
    };

    validate = (data) => {
        let errors = {};
        if (!data.firstName) errors.firstName = 'Name name is mandatory.';
        if (!data.email){
            errors.email = 'Email is mandatory.';
        } else if(!validateEmail(data.email)) {
            errors.email = 'Format email not valid.';
        }
        if (!data.password) errors.password = 'Password is mandatory.';
        if (!data.confirmPassword){
            errors.confirmPassword = 'ConfirmPassword is mandatory.';
        } else if(data.password !== data.confirmPassword) {
            errors.confirmPassword = 'Password and confirmPassword are not match.';
        }
        if (!data.acceptedTermConditios) errors.acceptedTermConditios = 'You must accept terms and conditions.';
        return errors;

    };

    sendLogin = () => {
        this.props.history.push("/login");
    }
    showPassword1 () {
        var tipo = document.getElementById("password");
        if(tipo.type === "password"){
            tipo.type = "text";
        }else{
            tipo.type = "password";
        }
        return false;
    }
    showPassword2 () {
        var tipo = document.getElementById("password");
        if(tipo.type === "password"){
            tipo.type = "text";
        }else{
            tipo.type = "password";
        }
        return false;
    }


    render() {
        const termsofuseDialog = (
            <Dialog onHide={() => this.onHide('displayBasic2', 'top')} header="Terms of Use " visible={this.state.displayBasic2} style={{ width: '80vw', textAlign: 'justify'}} >
                <h4>Last Modified: June 30, 2023</h4>
                <h1> Acceptance of the Terms of Use </h1>
                These terms of use are entered into by and between You and Go Stax Group, LLC ("Company,"
                "we," or "us"). The following terms and conditions, together with any documents they
                expressly incorporate by reference (collectively, "Terms of Use"), govern your access to and
                use of <b style={{color: "#E0C46C"}}>www.gostax.com</b>, including any content, functionality and services offered on or
                through www.gostax.com/ (the "Website"), whether as a guest or a registered user.
                Please read the Terms of Use carefully before you start to use the Website. By using the
                Website, you accept and agree to be bound and abide by these Terms of Use and our Privacy
                Policy, found at <b style={{color: "#E0C46C"}}>www.gostax.com/PrivacyPolicy</b>, incorporated herein by reference. If you do
                not want to agree to these Terms of Use or the Privacy Policy, you must not access or use the
                Website.
                This Website is offered and available to users who are 18 years of age or older, reside in the
                United States or any of its territories or possessions. By using this Website, you represent
                and warrant that you are of legal age to form a binding contract with the Company and meet
                all of the foregoing eligibility requirements. If you do not meet all of these requirements, you
                must not access or use the Website.
                    <h1>Changes to the Terms of Use</h1>
                We may revise and update these Terms of Use from time to time in our sole discretion. All
                changes are effective immediately when we post them, and apply to all access to and use of
                the Website thereafter.
                Your continued use of the Website following the posting of revised Terms of Use means that
                you accept and agree to the changes. You are expected to check this page each time you
                access this Website so you are aware of any changes, as they are binding on you.
                    <h1>Accessing the Website and Account Security</h1>
                We reserve the right to withdraw or amend this Website, and any service or material we
                provide on the Website, in our sole discretion without notice. We will not be liable if for any
                reason all or any part of the Website is unavailable at any time or for any period. From time
                to time, we may restrict access to some parts of the Website, or the entire Website, to users,
                including registered users


                    <h1>You are responsible for both:</h1>

                    <p>• Making  all  arrangements  necessary  for  you  to  have  access  to  the  Website.</p>
                    <p>• Ensuring that all persons who access the Website through your internet connection are
                        aware of these Terms of Use and comply with them.</p>
                <p>To access the Website or some of the resources it offers, you may be asked to provide certain
                registration details or other information. It is a condition of your use of the Website that all
                the information you provide on the Website is correct, current and complete. You agree that
                all information you provide to register with this Website or otherwise, including, but not
                limited to, through the use of any interactive features on the Website, is governed by
                our Privacy Policy, and you consent to all actions we take with respect to your information
                consistent with our Privacy Policy.</p>
                <p>If you choose, or are provided with, a user name, password or any other piece of information
                as part of our security procedures, you must treat such information as confidential, and you
                must not disclose it to any other person or entity. You also acknowledge that your account is
                personal to you and agree not to provide any other person with access to this Website or
                portions of it using your user name, password or other security information. You agree to
                notify us immediately of any unauthorized access to or use of your user name or password
                or any other breach of security. You also agree to ensure that you exit from your account at
                the end of each session. You should use particular caution when accessing your account from
                a public or shared computer so that others are not able to view or record your password or
                other personal information.</p>
                <p>We have the right to disable any user name, password or other identifier, whether chosen by
                you or provided by us, at any time in our sole discretion for any or no reason, including if, in
                    our opinion, you have violated any provision of these Terms of Use.</p>
                    <h1>Intellectual Property Rights</h1>
                    <p>The Website and its entire contents, features and functionality (including but not limited to
                        all information, software, text, displays, images, video and audio, and the design, selection
                        and arrangement thereof), are owned by the Company, its licensors or other providers of
                        such material and are protected by United States and international copyright, trademark,
                        patent, trade secret and other intellectual property or proprietary rights laws.</p>
                <p>These Terms of Use permit you to use the Website for your personal, non-commercial use
                    only. You must not reproduce, distribute, modify, create derivative works of, publicly display,
                    publicly perform, republish, download, store or transmit any of the material on our Website,
                    except as follows: </p>
                    <p>• Your computer may temporarily store copies of such materials in RAM incidental to your
                        accessing and viewing those materials.</p>
                    <p>• You may store files that are automatically cached by your Web browser for display
                        enhancement purposes.</p>
                    <p>• You may print one copy of a reasonable number of pages of the Website for your own
                        personal, non-commercial use and not for further reproduction, publication or distribution.</p>
                    <p>• If we provide desktop, mobile or other applications for download, you may download a
                        single copy to your computer or mobile device solely for your own personal, non-commercial
                        use, provided you agree to be bound by our end user license agreement for such applications.</p>
                     <p>You must not:</p>

                    <p>• Modify copies of any materials from this site.</p>
                    <p>• Use any illustrations, photographs, video or audio sequences or any graphics separately
                        from the accompanying text.</p>
                    <p>• Delete or alter any copyright, trademark or other proprietary rights notices from copies of
                        materials from this site.</p>
                    <p>You must not access or use for any commercial purposes any part of the Website or any
                        services or materials available through the Website.</p>

                    <p>If you wish to make any use of material on the Website other than that set out in this section,
                        please address your request to: support@gostax.com.</p>
                        <p>If you print, copy, modify, download or otherwise use or provide any other person with
                            access to any part of the Website in breach of the Terms of Use, your right to use the Website
                            will cease immediately and you must, at our option, return or destroy any copies of the
                            materials you have made. No right, title or interest in or to the Website or any content on the
                            Website is transferred to you, and all rights not expressly granted are reserved by the
                            Company. Any use of the Website not expressly permitted by these Terms of Use is a breach
                            of these Terms of Use and may violate copyright, trademark and other laws.</p>
                <h1>Trademarks</h1>
                The Company name, the terms Ewallet International, and all related names, logos, product
                and service names, designs and slogans are trademarks of the Company or its affiliates or
                licensors. You must not use such marks without the prior written permission of the
                Company. All other names, logos, product and service names, designs and slogans on this
                Website are the trademarks of their respective owners.
                <h1>Prohibited Uses</h1>
                You may use the Website only for lawful purposes and in accordance with these Terms of
                Use. You agree not to use the Website:
                        <p>• In any way that violates any applicable federal, state, local or international law or
                            regulation (including, without limitation, any laws regarding the export of data or software
                            to and from the US or other countries). </p>
                        <p>• For the purpose of exploiting, harming or attempting to exploit or harm minors in any way
                            by exposing them to inappropriate content, asking for personally identifiable information or
                            otherwise.</p>
                    <p>• To send, knowingly receive, upload, download, use or re-use any material which does not
                        comply with the Content Standards set out in these Terms of Use.</p>
                    <p>• To transmit, or procure the sending of, any advertising or promotional material, including
                        any "junk mail," "chain letter," "spam," or any other similar solicitation.</p>
                    <p>• To impersonate or attempt to impersonate the Company, a Company employee, another user
                        or any other person or entity (including, without limitation, by using e-mail addresses
                        associated with any of the foregoing).</p>
                    <p>• To impersonate or attempt to impersonate the Company, a Company employee, another
                        user or any other person or entity (including, without limitation, by using e-mail addresses
                        associated with any of the foregoing). </p>
                    <p>• To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the
                    Website, or which, as determined by us, may harm the Company or users of the Website, or
                    expose them to liability.</p>
                    <h1>Additionally, you agree not to:</h1>
                    <p>• Use the Website in any manner that could disable, overburden, damage, or impair the site
                        or interfere with any other party's use of the Website, including their ability to engage in real
                        time activities through the Website.</p>
                    <p>• Use any robot, spider or other automatic device, process or means to access the Website
                        for any purpose, including monitoring or copying any of the material on the Website.</p>
                    <p>• Use any manual process to monitor or copy any of the material on the Website, or for any
                        other purpose not expressly authorized in these Terms of Use, without our prior written
                        consent. </p>
                    <p>• Use any device, software or routine that interferes with the proper working of the Website.</p>
                    <p>• Introduce any viruses, trojan horses, worms, logic bombs or other material which is
                        malicious or technologically harmful. </p>
                    <p>• Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the
                        Website, the server on which the Website is stored, or any server, computer or database
                        connected to the Website.</p>
                    <p>• Attack the Website via a denial-of-service attack or a distributed denial-of-service attack.</p>
                    <p>• Otherwise attempt to interfere with the proper working of the Website.</p>
                    <h1>User Contributions</h1>
                The Website may contain message boards, chat rooms, personal web pages or profiles,
                forums, bulletin boards, and other interactive features (collectively, "Interactive Services")
                that allow users to post, submit, publish, display or transmit to other users or other persons
                (hereinafter, "post") content or materials (collectively, "User Contributions") on or through
                the Website.
                <p>All User Contributions must comply with the Content Standards set out in these Terms of
                    Use.</p>


                <p>Any User Contribution you post to the site will be considered non-confidential and non-
                    proprietary. By providing any User Contribution on the Website, you grant us and our
                    affiliates and service providers, and each of their and our respective licensees, successors
                    and assigns the right to use, reproduce, modify, perform, display, distribute and otherwise
                    disclose to third parties any such material for any purpose.</p>
                <h1>You represent and warrant that:</h1>
                    <p>• You own or control all rights in and to the User Contributions and have the right to grant
                        the license granted above to us and our affiliates and service providers, and each of their and
                        our respective licensees, successors and assigns. </p>
                    <p>• All of your User Contributions do and will comply with these Terms of Use.</p>
                <p>You understand and acknowledge that you are responsible for any User Contributions you
                    submit or contribute, and you, not the Company, have fully responsibility for such content,
                    including its legality, reliability, accuracy and appropriateness. We are not responsible, or
                    liable to any third party, for the content or accuracy of any User Contributions posted by you
                    or any other user of the Website.</p>
                    <h1>Monitoring and Enforcement; Termination</h1>
                We have the right to:
                    <p>• Remove or refuse to post any User Contributions for any or no reason in our sole discretion. </p>
                    <p>• Take any action with respect to any User Contribution that we deem necessary or
                        appropriate in our sole discretion, including if we believe that such User Contribution
                        violates the Terms of Use, including the Content Standards, infringes any intellectual
                        property right or other right of any person or entity, threatens the personal safety of users
                        of the Website or the public or could create liability for the Company. </p>
                    <p>• Disclose your identity or other information about you to any third party who claims that
                        material posted by you violates their rights, including their intellectual property rights or
                        their right to privacy.</p>
                    <p>• Take appropriate legal action, including without limitation, referral to law enforcement, for
                        any illegal or unauthorized use of the Website.</p>
                    <p>• Terminate or suspend your access to all or part of the Website for any or no reason,
                        including without limitation, any violation of these Terms of Use.</p>
                <p>Without  limiting  the  foregoing,  we  have  the  right  to  fully  cooperate  with  any  law
                    enforcement authorities or court order requesting or directing us to disclose the identity or
                    other information of anyone posting any materials on or through the Website. YOU WAIVE
                    AND HOLD HARMLESS THE COMPANY AND ITS AFFILIATES, LICENSEES AND SERVICE
                    PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE
                    FOREGOING PARTIES DURING OR AS A RESULT OF ITS INVESTIGATIONS AND FROM ANY
                    ACTIONS TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER SUCH PARTIES OR
                    LAW ENFORCEMENT AUTHORITIES.</p>
                    <p>However, we do not undertake to review all material before it is posted on the Website, and
                        cannot  ensure  prompt  removal  of  objectionable  material  after  it  has  been  posted.
                        Accordingly, we assume no liability for any action or inaction regarding transmissions,
                        communications or content provided by any user or third party. We have no liability or
                        responsibility to anyone for performance or nonperformance of the activities described in
                        this section. </p>
                <h1>Content Standards</h1>
                These content standards apply to any and all User Contributions and use of Interactive
                Services. User Contributions must in their entirety comply with all applicable federal, state,
                local  and  international  laws  and  regulations.  Without  limiting  the  foregoing,  User
                Contributions must not:
                <p>• Contain any material which is defamatory, obscene, indecent, abusive, offensive, harassing,
                    violent, hateful, inflammatory or otherwise objectionable. </p>
                    <p>•  Promote sexually explicit or pornographic material, violence, or discrimination based on
                        race, sex, religion, nationality, disability, sexual orientation or age.</p>
                    <p>• Infringe any patent, trademark, trade secret, copyright or other intellectual property or
                        other rights of any other person. </p>
                    <p>• Violate the legal rights (including the rights of publicity and privacy) of others or contain
                        any material that could give rise to any civil or criminal liability under applicable laws or
                        regulations or that otherwise may be in conflict with these Terms of Use and our Privacy
                        Policy. </p>
                    <p>• Be likely to deceive any person.</p>
                    <p>• Promote any illegal activity, or advocate, promote or assist any unlawful act. </p>
                    <p>• Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm
                        or annoy any other person.</p>
                    <p>• Impersonate any person, or misrepresent your identity or affiliation with any person or
                        organization.</p>
                    <p>• Involve commercial activities or sales, such as contests, sweepstakes and other sales
                        promotions, barter or advertising.</p>
                    <p>• Give the impression that they emanate from or are endorsed by us or any other person or
                        entity, if this is not the case. </p>

                    <h1>Copyright Infringement</h1>
                If you believe that any User Contributions violate your copyright, please send us a notice of
                copyright infringement to support@gostax.com. It is the policy of the Company to terminate
                the user accounts of repeat infringers.
                <h1>Reliance on Information Posted</h1>
                The information presented on or through the Website is made available solely for general
                information purposes. We do not warrant the accuracy, completeness or usefulness of this
                information. Any reliance you place on such information is strictly at your own risk. We
                disclaim all liability and responsibility arising from any reliance placed on such materials by
                you or any other visitor to the Website, or by anyone who may be informed of any of its
                contents.
                <p>This Website may include content provided by third parties, including materials provided by
                    other users, bloggers and third-party licensors, syndicators, aggregators and/or reporting
                    services. All statements and/or opinions expressed in these materials, and all articles and
                    responses to questions and other content, other than the content provided by the Company,
                    are solely the opinions and the responsibility of the person or entity providing those
                    materials. These materials do not necessarily reflect the opinion of the Company. We are not
                    responsible, or liable to you or any third party, for the content or accuracy of any materials
                    provided by any third parties. </p>
                    <h1>Changes to the Website</h1>
                We may update the content on this Website from time to time, but its content is not
                necessarily complete or up-to-date. Any of the material on the Website may be out of date at
                any given time, and we are under no obligation to update such material.
                <h1>Information About You and Your Visits to the Website</h1>
                All information we collect on this Website is subject to our Privacy Policy. By using the
                Website, you consent to all actions taken by us with respect to your information in
                compliance with the Privacy Policy
                <h1>Online Purchases and Other Terms and Conditions</h1>
                All purchases through our site or other transactions for the sale of services formed through
                the Website, or as a result of visits made by you are governed by our Terms of Sale, as may
                be altered from time to time, which may be conveyed as applicable on the Website and which
                are hereby incorporated into these Terms of Use.
                <p>Additional terms and conditions may also apply to specific portions, services or features of
                    the Website. All such additional terms and conditions are hereby incorporated by this
                    reference into these Terms of Use.</p>
                    <h1>Linking to the Website and Social Media Features</h1>
                You may link to our homepage, provided you do so in a way that is fair and legal and does
                not damage our reputation or take advantage of it, but you must not establish a link in such
                a way as to suggest any form of association, approval or endorsement on our part
                <p>This Website may provide certain social media features that enable you to:</p>
                    <p>• Link from your own or certain third-party websites to certain content on this Website.</p>
                    <p>• Send e-mails or other communications with certain content, or links to certain content, on
                        this Website.</p>
                    <p>• Cause limited portions of content on this Website to be displayed or appear to be displayed
                        on your own or certain third-party websites.</p>
                You may use these features solely as they are provided by us and solely with respect to the
                content they are displayed with. Subject to the foregoing, you must not:
                    <p>• Establish a link from any website that is not owned by you.</p>
                    <p>• Cause the Website or portions of it to be displayed, or appear to be displayed by, for
                        example, framing, deep linking or in-line linking, on any other site.</p>
                    <p>• Link to any part of the Website other than the homepage.</p>
                    <p>• Otherwise take any action with respect to the materials on this Website that is inconsistent
                        with any other provision of these Terms of Use. </p>
                The website from which you are linking, or on which you make certain content accessible,
                must comply in all respects with the content standards set out in these Terms of Use.
                You agree to cooperate with us in causing any unauthorized framing or linking immediately
                to cease. We reserve the right to withdraw linking permission without notice.
                We may disable all or any social media features and any links at any time without notice in
                our discretion.
                    <h1>Links from the Website</h1>
                If the Website contains links to other sites and resources provided by third parties, these
                links  are  provided  for  your  convenience  only.  This  includes  links  contained  in
                advertisements, including banner advertisements and sponsored links. We have no control
                over the contents of those sites or resources, and accept no responsibility for them or for any
                loss or damage that may arise from your use of them. If you decide to access any of the third
                party websites linked to this Website, you do so entirely at your own risk and subject to the
                terms and conditions of use for such websites
                    <h1>Geographic Restrictions</h1>
                The owner of the Website is based in the United States. We provide this Website for use only
                by persons located in the United States. We make no claims that the Website or any of its
                content is accessible or appropriate outside of the United States. Access to the Website may
                not be legal by certain persons or in certain countries. If you access the Website from outside
                the United States, you do so on your own initiative and are responsible for compliance with
                local laws.


                    <h1>Disclaimer of Warranties</h1>
                You understand that we cannot and do not guarantee or warrant that files available for
                downloading from the internet or the Website will be free of viruses or other destructive
                code. You are responsible for implementing sufficient procedures and checkpoints to satisfy
                your particular requirements for anti-virus protection and accuracy of data input and output,
                and for maintaining a means external to our site for any reconstruction of any lost data. WE
                WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-
                SERVICE ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT
                MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA OR OTHER
                PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ANY SERVICES OR
                ITEMS  OBTAINED  THROUGH  THE  WEBSITE  OR  TO  YOUR  DOWNLOADING  OF  ANY
                MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT.
                <p>YOUR USE OF THE WEBSITE, ITS CONTENT AND ANY SERVICES OR ITEMS OBTAINED
                    THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE, ITS CONTENT AND ANY
                    SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE ARE PROVIDED ON AN "AS IS"
                    AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS
                    OR  IMPLIED.  NEITHER  THE  COMPANY  NOR  ANY  PERSON  ASSOCIATED  WITH  THE
                    COMPANY  MAKES  ANY  WARRANTY  OR  REPRESENTATION  WITH  RESPECT  TO  THE
                    COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY OR AVAILABILITY OF THE
                    WEBSITE. WITHOUT LIMITING THE FOREGOING, NEITHER THE COMPANY NOR ANYONE
                    ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT THE WEBSITE, ITS
                    CONTENT OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE WILL BE
                    ACCURATE,  RELIABLE,  ERROR-FREE  OR  UNINTERRUPTED,  THAT  DEFECTS  WILL  BE
                    CORRECTED, THAT OUR SITE OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF
                    VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITE OR ANY SERVICES
                    OR ITEMS OBTAINED THROUGH THE WEBSITE WILL OTHERWISE MEET YOUR NEEDS OR
                    EXPECTATIONS. </p>
                <p>THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
                    OR  IMPLIED,  STATUTORY  OR  OTHERWISE,  INCLUDING  BUT  NOT  LIMITED  TO  ANY
                    WARRANTIES  OF  MERCHANTABILITY,  NON-INFRINGEMENT  AND  FITNESS  FOR
                    PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH
                    CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW. </p>
                    <h1>Limitation on Liability</h1>
                TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
                AFFILIATES OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS
                OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY,
                ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE
                WEBSITE, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE WEBSITE OR SUCH OTHER
                WEBSITES OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE WEBSITE OR SUCH
                OTHER  WEBSITES,  INCLUDING  ANY  DIRECT,  INDIRECT,  SPECIAL,  INCIDENTAL,
                CONSEQUENTIAL OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL
                INJURY,  PAIN AND  SUFFERING,  EMOTIONAL DISTRESS, LOSS  OF REVENUE,  LOSS  OF
                PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL,
                LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
                CONTRACT OR OTHERWISE, EVEN IF FORESEEABLE.
                THE FOREGOING DOES NOT AFFECT ANY LIABILITY WHICH CANNOT BE EXCLUDED OR
                LIMITED UNDER APPLICABLE LAW.
                    <h1>Indemnification</h1>
                You agree to defend, indemnify and hold harmless the Company, its affiliates, licensors and
                service providers, and its and their respective officers, directors, employees, contractors,
                agents, licensors, suppliers, successors and assigns from and against any claims, liabilities,
                damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys'
                fees) arising out of or relating to your violation of these Terms of Use or your use of the
                Website, including, but not limited to, your User Contributions, any use of the Website's
                content, services and products other than as expressly authorized in these Terms of Use, or
                your use of any information obtained from the Website.
                    <h1>Governing Law and Jurisdiction</h1>
                All matters relating to the Website and these Terms of Use, and any dispute or claim arising
                therefrom or related thereto (in each case, including non-contractual disputes or claims),
                shall be governed by and construed in accordance with the internal laws of the State of
                Delaware without giving effect to any choice or conflict of law provision or rule (whether of
                the State of Delaware or any other jurisdiction).
                <p>Any legal suit, action or proceeding arising out of, or related to, these Terms of Use or the
                    Website shall be instituted exclusively in the federal courts of the United States or the courts
                    of the State of Delaware, in each case located in the City of Wilmington and County of New
                    Castle, although we retain the right to bring any suit, action or proceeding against you for
                    breach of these Terms of Use in your country of residence or any other relevant country. You
                    waive any and all objections to the exercise of jurisdiction over you by such courts and to
                    venue in such courts. </p>
                    <h1>Arbitration</h1>
                At Company's sole discretion, it may require You to submit any disputes arising from these
                Terms of Use or use of the Website, including disputes arising from or concerning their
                interpretation, violation, invalidity, non-performance, or termination, to final and binding
                arbitration under the Rules of Arbitration of the American Arbitration Association applying
                Delaware law
                    <h1>Limitation on Time to File Claims</h1>
                    ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO
                THESE TERMS OF USE OR THE WEBSITE MUST BE COMMENCED WITHIN ONE (1) YEAR
                AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM
                IS PERMANENTLY BARRED.
                    <h1>Waiver and Severability</h1>
                No waiver of by the Company of any term or condition set forth in these Terms of Use shall
                be deemed a further or continuing waiver of such term or condition or a waiver of any other
                term or condition, and any failure of the Company to assert a right or provision under these
                Terms of Use shall not constitute a waiver of such right or provision.
                <p>If any provision of these Terms of Use is held by a court or other tribunal of competent
                    jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be
                    eliminated or limited to the minimum extent such that the remaining provisions of the Terms
                    of Use will continue in full force and effect.</p>

                    <h1>Entire Agreement</h1>
                The Terms of Use, our Privacy Policy, and Terms of Sale constitute the sole and entire
                agreement between you and the Company with respect to the Website and supersede all
                prior and contemporaneous understandings, agreements, representations and warranties,
                both written and oral, with respect to the Website
                    <h1>Your Comments and Concerns</h1>
                In the event a dispute arises between you the Company, our goal is to provide you with a
                neutral and cost effective means of resolving the dispute quickly. Accordingly, you and the
                Company agree that any controversy or claim at law or equity that arises out of this
                Agreement or the Company’s Service (“Claims”) shall be resolved by arbitration. The
                arbitration  shall  be  conducted  in  Washington,  District  of  Columbia,  USA  by  a  single
                arbitrator, in the English language. The Rules of Arbitration shall be as prescribed under the
                Arbitrations Act (USA) and the decision of the arbitrator shall be final and binding upon all
                the parties thereto and shall not be subject to appeal. The cost of any arbitration shall be
                borne equally by the parties. ARBITRATION MUST BE ON AN INDIVIDUAL BASIS. THIS
                MEANS NEITHER WE NOR YOU MAY JOIN OR CONSOLIDATE CLAIMS IN ARBITRATION BY
                OR AGAINST OTHER ACCOUNTHOLDERS AS A CLASS.
                This website is powered by CoPilot Global LLC d/b/a Ewallet International (“EWI”) on behalf
                of Go Stax Group, LLC. All protections conferred to Company hereunder shall also apply to
                EWI. All feedback, comments, requests for technical support and other communications
                relating to the Website should be directed to: <b style={{color: "#E0C46C"}}>support@gostax.com.</b>
                <p>Banking services provided by Patriot Nank, N.A. Member FDIC. The payment cards provided in connection </p>
                <p>herewith are issued pursuant to a license from MasterCard U.S.A., Inc. and may be used everywhere a </p>
                <p>MasterCard debit or credit card is accepted. By clicking on some of the links herein, you will leave the </p>
                <p>Company website and be directed to a third-party website. The privacy practices of those third parties </p>
                <p>may differ from those of Company. We recommend you review the privacy statements of those third-party </p>
                <p>websites, as Comapny is not responsible for those third parties’ privacy or security practices. ©2023 Go </p>
                <p>Stax Group, LLC. All Rights Reserved.
                    Privacy Policy</p>
                <h1>Contact</h1>
                <p style={{color: "#E0C46C"}}>support@gostax.com </p>

            </Dialog>


        )
        const {
            firstName,
            email,
            password,
            confirmPassword,
            errors
        } = this.state;

        return (
            <div style={{display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw', overflow: 'auto'}}>
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}} />
                {/* Mitad 1 - Con la imagen */}
                <div  className="imgDiv" style={{flex: 1, position: 'relative', background: `url('assets/ewallet/img/singin.jpeg') no-repeat center center / cover`}}>
                    <img src='assets/ewallet/img/img_1.png' alt='Overlaid'
                         style={{
                             position: 'absolute',
                             bottom: 0,
                             left: 0,
                             opacity: 0.5
                         }} />
                </div>
                {/* Mitad 2 - Vacía */}
                <div className="outerDiv">
                    <div className="innerDiv" style={{paddingTop: '0px'}}>
                        <div style={{textAlign: 'right'}}>
                            <div style={{display: 'inline-block'}}>
                                <img src='assets/ewallet/img/img_2.png' alt='Gostax' style={{width: '60%'}}/>
                                <div style={{borderBottom: '1px solid black'}}>
                                    <p style={{
                                        width: '100%',
                                        fontSize: '26px',
                                        color: '#223035',
                                        marginTop: '0px'
                                    }}>Sign Up Today With GoStax</p>
                                </div>
                            </div>
                        </div>
                        <div className="form-group" style={{paddingTop: '2px'}}>
                            <label style={{
                                display: 'block',
                                color: '#555658',
                                fontFamily: '"Roboto", Sans-serif',
                                fontSize: 16,
                                fontWeight: 500,
                                lineHeight: '21px',
                                paddingTop: '15px'
                            }}>Your Name</label>
                            <span className="p-input-icon-left p-fluid">
                                <InputText
                                    name="firstName"
                                    style={{color: 'black', height: '50px'}}
                                    value={firstName}
                                    placeholder="Enter Your Full Name Here"
                                    onKeyPress={this._handleKeyPress}
                                    onChange={this.changeHandler}
                                />
                            </span>
                        </div>
                        <div className="mb-20 ta-right">
                            {errors?.firstName !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.firstName}</small>
                                : ""
                            }
                        </div>
                        <div className="form-group" style={{paddingTop: '2px'}}>
                            <label style={{
                                display: 'block',
                                color: '#555658',
                                fontFamily: '"Roboto", Sans-serif',
                                fontSize: 16,
                                fontWeight: 500,
                                lineHeight: '21px'
                            }}>Email</label>
                            <span className="p-input-icon-left p-fluid">
                                <InputText
                                    name="email"
                                    autoComplete="new-password"
                                    style={{color: 'black', height: '50px'}}
                                    value={email}
                                    placeholder="Enter Your Email Here"
                                    onKeyPress={this._handleKeyPress}
                                    onChange={this.changeHandler}
                                />
                            </span>
                        </div>
                        <div className="mb-20 ta-right">
                            {errors?.email !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.email}</small>
                                : ""
                            }
                        </div>

                        <div className="form-group">
                            <label
                                style={{
                                    display: 'block',
                                    color: '#555658',
                                    fontFamily: '"Roboto", Sans-serif',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: '21px'
                                }}
                            >
                                Password
                            </label>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputText
                                    name="password"
                                    placeholder="Create Your GoStax Password Here"
                                    id="password"
                                    onKeyPress={this._handleKeyPress}
                                    value={password}
                                    onChange={this.changeHandler}
                                    type="password"
                                    style={{
                                        flexGrow: 1,
                                        color: 'black',
                                        height: '50px',
                                        borderRadius: '5px 0 0 5px',
                                        borderRight: 'none'
                                    }}
                                />

                                <Button
                                    icon="fa fa-eye"
                                    className="p-button-text"
                                    style={{
                                        height: '50px',
                                        backgroundColor: 'rgb(34, 48, 53)',
                                        borderRadius: '0px 5px 5px 0px',
                                        borderRightWidth: '0px',
                                        marginRight: '0px',
                                    }}
                                    onClick={this.showPassword}
                                />
                            </div>
                        </div>
                        <div className="mb-20 ta-right">
                            {errors?.password !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.password}</small>
                                : ""
                            }
                        </div>
                        <div className="form-group">
                            <label
                                style={{
                                    display: 'block',
                                    color: '#555658',
                                    fontFamily: '"Roboto", Sans-serif',
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: '21px'
                                }}
                            >
                                Repeat Password
                            </label>

                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputText
                                    name="confirmPassword"
                                    placeholder="Repeat Your GoStax Password Here"
                                    id="password"
                                    onKeyPress={this._handleKeyPress}
                                    type="password"
                                    value={confirmPassword}
                                    onChange={this.changeHandler}
                                    style={{
                                        flexGrow: 1,
                                        color: 'black',
                                        height: '50px',
                                        //borderRadius: '5px 0 0 5px',
                                        //borderRight: 'none'
                                    }}
                                />
                            </div>
                        </div>
                        <div className="mb-20 ta-right">
                            {errors?.confirmPassword !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.confirmPassword}</small>
                                : ""
                            }
                        </div>
                        <div className="form-group" style={{marginBottom: '10px'}}>
                            <Checkbox inputId="binary" checked={this.state.acceptedTermConditios}
                                      name="acceptedTermConditios"
                                      onChange={this.changeHandler}/>
                            <label htmlFor="agree-term" className="label-agree-term" style={{color: "#E0C46C"}}>
                                I agree all statements in <span style={{width: 5}}></span>
                                <Button onClick={() => this.onClick('displayBasic2', 'top')}
                                        label="terms of use"/*style="border-left-width: 0px"*/
                                        className="p-button-sm term-service"
                                        style={{
                                            borderLeft: 10,
                                            height: 32,
                                            width: 107,
                                            textDecoration: "underline",
                                            border: "0px",
                                            paddingLeft: "0px"/*color: "#0062cc"*/
                                        }}>
                                </Button>
                            </label>
                            <br/>
                            {errors?.acceptedTermConditios !== undefined ?
                                <small className="p-messages p-messages-error">{errors?.acceptedTermConditios}</small>
                                : ""
                            }
                        </div>
                        <div className="p-grid">
                            <div className="p-col-12 ta-right">
                                <Button label="Register" style={{
                                    fontSize: '16px',
                                    fontFamily: '"Roboto", Sans-serif',
                                    padding: '10px 20px',
                                    borderRadius: '40px',
                                    backgroundColor: '#223035',
                                    boxShadow: '0px 3px 6px #00000029',
                                    width: '150px'
                                }}
                                        disabled={this.state.processing}
                                        onClick={this.submitHandler}
                                />
                            </div>
                        </div>
                        <span style={{
                            display: 'block',
                            color: '#555658',
                            fontFamily: '"Roboto", Sans-serif',
                            fontSize: 13,
                            fontWeight: 500,
                            lineHeight: '21px'
                        }}>
                            I Am Already A GoStax Member.
                            <a
                                href="#/login"
                                onClick={this.sendLogin}
                                style={{textDecoration: "underline" /*color: "#0062cc"*/}}
                            >
                              Click Here To Log In.
                            </a>
                        </span>
                        <span style={{
                            display: 'block',
                            color: '#555658',
                            fontFamily: '"Roboto", Sans-serif',
                            fontSize: 13,
                            fontWeight: 500,
                            lineHeight: '21px',
                            paddingTop: '10px'
                        }}>
                            For support signing up with your new GoStax account, email us at support@gostax.com for assistance.
                            © Copyright. Go Stax Group, LLC, 2023
                        </span>
                    </div>
                </div>
                {termsofuseDialog}
            </div>
        )
    }
}

export default SignUp;
