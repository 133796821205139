import axios from 'axios';
import {EXPRESS, NODE_WALLET_BACK} from "../../util/constants";

export class BackendService {
    getConfig = params => {
        return {
            params,
            headers: {
                'Content-Type': 'application/json',
            },
        };
    };

    getConfigSecurity = params => {
        const token_backend = localStorage.getItem('access_token');
        return {
            params,
            headers: {
                Authorization: `Bearer ${token_backend}`,
            },
        };
    };

    getConfigGuest = params => {
        const accessToken = 'V33ntOZLQGKH8Nba40ORU5glQlxWqh';
        return {
            params,
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
    };

    /**
     * signup
     */
    postSignup(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/signup`,
            params,
            this.getConfig(),
        ).then(resp => resp.data);
    }

    postLogin(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/token/`, params, this.getConfig()).then(resp => resp.data);
    }

    postForgotPassword(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/forgotPassword/`,
            params,
            this.getConfig()).then(resp => resp.data);
    }

    getProfile() {
        return axios.get(`${NODE_WALLET_BACK}/api/profile`, this.getConfigSecurity()).then(resp => resp.data);
    }
    getStatusKYC() {
        return axios.get(`${NODE_WALLET_BACK}/api/v2/kyc/status/`, this.getConfigSecurity()).then(resp => resp.data);
    }
    listVendors() {
        return axios.get(`${NODE_WALLET_BACK}/api/bank/vendor/local/list/`, this.getConfigSecurity()).then(resp => resp.data);
    }
    listAllVendors() {
        return axios.get(`${NODE_WALLET_BACK}/api/bank/vendor/list/`, this.getConfigSecurity()).then(resp => resp.data);
    }
    listStatusVendors() {
        return axios.get(`${NODE_WALLET_BACK}/api/bank/vendor/request/list/`, this.getConfigSecurity()).then(resp => resp.data);
    }
    newVendor(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/bank/vendor/link/`,params, this.getConfigSecurity()).then(resp => resp.data);
    }
    addVendor(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/bank/vendor/local/add/`,params, this.getConfigSecurity()).then(resp => resp.data);
    }
    payVendor(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/bank/vendor/pay/`,params, this.getConfigSecurity()).then(resp => resp.data);
    }


    getAllInfoBtc(provider_accountid) {
        return axios.get(`${NODE_WALLET_BACK}/api/allinfobtc/${provider_accountid}/`,
            this.getConfigSecurity()).then(resp => resp.data);
    }

    getReceiveAddress(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/getAddress`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getPaymentAddress(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/paymentAddress`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postLoadFundsWallet(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/loadFundsWallet/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postLoadFundsCardWallet(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/loadFundsCard/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postTopUpAccountEmail(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/admin/email/payment/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp);
    }

    postUploadDocument(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/kyc/upload/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getKycDocuments() {
        return axios.get(`${NODE_WALLET_BACK}/api/kyc/user/list/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getListRecipient() {
        return axios.get(`${NODE_WALLET_BACK}/api/recipients/list`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postTransferAccount(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/transferAccount/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postAddFunds(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/card/add/funds/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getCatalogs() {
        return axios.get(
            `${NODE_WALLET_BACK}/api/profile/list/catalogos/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postReportHistory(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/account/report/account/history/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postUpdateProfile(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/profile/update/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postKycCapture(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/kyc/capture/response/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }
    postCodeData(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/bank/vendor/validation/code/vendor/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }
    postSendEmailCode(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/bank/vendor/code/vendor/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postChangePassword(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/changePassword/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    postResetPassword(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/resetPassword/`,
            params,
            this.getConfig(),
        ).then(resp => resp.data);
    }

    refreshToken(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/token/refresh/`,
            params,
            this.getConfig(),
        ).then(resp => resp.data);
    }

    getCatalogRateCurrency() {
        return axios.get(
            `${EXPRESS}/api/v/1.0/service/rate/currency/all/`,
            this.getConfigGuest(),
        ).then(resp => resp.data);
    }

    getRateCurrency(params) {
        return axios.post(
            `${EXPRESS}/api/v/1.0/service/rate/currency/`,
            params,
            this.getConfigGuest(),
        ).then(resp => resp);
    }

    getBeneficiariesTheRemittance() {
        return axios.get(
            `${EXPRESS}/api/v/1.0/remittance/list_beneficiaries/`,
            this.getConfigGuest(),
        ).then(resp => resp);
    }

    getCatalogSwift(countryCode) {
        return axios.get(
            `${NODE_WALLET_BACK}/api/profile/swift/${countryCode}/`,
        ).then(resp => resp);
    }

    postCreateRemittance(params) {
        return axios.post(
            `${EXPRESS}/api/v/1.0/remittance/create/`,
            params,
            this.getConfigGuest(),
        ).then(resp => resp);
    }

    postRemittancePayment(params) {
        return axios.post(
            `${EXPRESS}/api/v/1.0/remittance/payment/`,
            params,
            this.getConfigGuest(),
        ).then(resp => resp);
    }

    postInvoiceCreate(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/v/1.0/service/invoice/create/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => {
            return resp.data;
        });
    }

    getVirtualCards() {
        return axios.get(
            `${NODE_WALLET_BACK}/api/card/list/virtualCard/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    addVirtualCard(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/card/funds/buy/virtualCard/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => {
            return resp.data;
        });
    }

    updateProfileKYC(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/accounts/profileWallet/update/`,
            params,
            this.getConfigGuest(),
        ).then(resp => {
            return resp.data;
        });
    }

    createAccountBest(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/accounts/createAccountBest/`,
            params,
            this.getConfigGuest(),
        ).then(resp => {
            return resp.data;
        });
    }

    addNewPhysicalCard(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/card/funds/buy/card/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => {
            return resp.data;
        });
    }

    getPhysicalCards() {
        return axios.get(
            `${NODE_WALLET_BACK}/api/card/list/card/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getListBanks(provider_accountid) {
        return axios.get(
            `${NODE_WALLET_BACK}/api/bank/account/list/${provider_accountid}/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getListBestBanks(provider_accountid) {
        return axios.get(
            `${NODE_WALLET_BACK}/api/bank/card/list/${provider_accountid}/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getLinkBankAccount(provider_accountid) {
        return axios.get(
            `${NODE_WALLET_BACK}/api/bank/account/link/${provider_accountid}/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }
    getLinkKYC() {
        return axios.get(
            `${NODE_WALLET_BACK}/api/kyc/link/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    historyKYC() {
        return axios.get(
            `${NODE_WALLET_BACK}/api/kyc/all/links/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getLinkBestBankAccount(provider_accountid) {
        return axios.get(
            `${NODE_WALLET_BACK}/api/bank/card/link/${provider_accountid}/`,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    addFundsACHToCard(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/cards/best/ach/`,
            params,
            this.getConfigGuest(),
        ).then(resp => resp);
    }

    getCardInfoBest(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/cards/best/info/`,
            params,
            //this.getConfigGuest(),
        ).then(resp => resp.data);
    }

    loadFundsTokenByLinkCard(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/loadFundsToken/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    deleteLinkBank(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/bank/link/delete/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    deleteCardLinkBank(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/bank/card/link/delete/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    loadFundsTokenByAccountCard(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/loadFundsBankToken/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    cardHistory(params) {
        return axios.post(
            `${NODE_WALLET_BACK}/api/card/history/transactions/`,
            params,
            this.getConfigSecurity()
        ).then(resp => resp.data);
    }

    getPaymentInfo(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/broker/invoice/info/`,
            params,
            this.getConfigGuest()
        ).then(resp => resp.data);
    }

    getListInfo(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/broker/invoice/wallet/list/`,
            params,
            this.getConfigGuest()
        ).then(resp => resp.data);
    }

    getListInfoByInvoice(params) {
        return axios.post(
            `${EXPRESS}/api/v/2.0/service/broker/invoice/wallet/list/byinvoice/`,
            params,
            this.getConfigGuest()
        ).then(resp => resp.data);
    }

    paymentInvoice(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/payment/invoice/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    resetPasswordService(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/resetPassword/`,
            params).then(resp => {
            return resp.data;
        });
    }

    getKYCStatus() {
        return axios.get(`${NODE_WALLET_BACK}/api/kyc/status/`, this.getConfigSecurity(),).then(resp => {
            return resp.data;
        });
    }

    getTokens() {
        return axios.get(`${NODE_WALLET_BACK}/api/auth/token/list/`, this.getConfigSecurity()).then(resp => {
            return resp.data;
        });
    }

    createToken(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/auth/token/create/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    revokeToken(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/auth/token/revoke/`,
            params,
            this.getConfigSecurity(),
        ).then(resp => resp.data);
    }

    getAccountList(params) {
        return axios.post(
            `${EXPRESS}/api/v/1.0/register/accounts/list/`,
            params, this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

    postTransferAccountBest(params) {
        return axios.post(`${NODE_WALLET_BACK}/api/transferAccount/`,
            params, this.getConfigSecurity()).then(resp => {
            return resp.data;
        });
    }

    addRegisterAccount(params) {
        return axios.post(`${EXPRESS}/api/v/1.0/register/accounts/add/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

    removeRegisterAccount(params) {
        return axios.post(`${EXPRESS}/api/v/1.0/register/accounts/remove/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

    addAccountBest(params) {
        return axios.post(`${EXPRESS}/api/v/2.0/service/accounts/addAccountBest/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

    bestRequestFunds(params) {
        return axios.post(`${EXPRESS}/api/v/2.0/service/accounts/best/request/funds/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

    transferWalletToAccount(params){
        return axios.post(`${EXPRESS}/api/v/2.0/service/broker/transfer_w_to_b/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }


    infoCard(params){
        return axios.post(`${NODE_WALLET_BACK}/api/card/info/`,
            params,
            this.getConfigSecurity()
        ).then(resp => {
            return resp.data;
        });
    }


    activateCard(params){
        return axios.post(`${NODE_WALLET_BACK}/api/activate/card/`,
            params,
            this.getConfigSecurity()).then(resp => {
            return resp.data;
        });
    }

    postFindStates(params){
        return axios.post(`${NODE_WALLET_BACK}/api/profile/list/countri/states/`,
            params,
            this.getConfigSecurity()).then(resp => {
            return resp.data;
        });
    }

    addFundsCard(params){
        return axios.post(`${NODE_WALLET_BACK}/api/v/2.0/service/broker/transfer_w_to_b/`,
            params,
            this.getConfigGuest()).then(resp => {
            return resp.data;
        });
    }

}
