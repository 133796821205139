import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Growl} from "primereact/growl";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ValidationKYC} from "../KYC/ValidationKYC";
import {Dialog} from "primereact/dialog";
import {loadProfile, logOut} from "../../util/commonFunctions";
import {Dropdown} from "primereact/dropdown";

export class ListInvoice extends Component {
    constructor() {
        super();
        this.state = {
            accounts: [],
            sender_account: '',

            invoiceNumber: '',
            listInvoice: [],
            dataTableSelection: {},
            confirmPayDisplay: false,
            displayConfirm: false,
            displayConfirmSend: false,
            actionToConfirm: null,
            detailInvoiceDisplay: false,
            linkToPay:'',
            errors: {},
        }
        this.backendService = new BackendService();
        this.searchInvoice = this.searchInvoice.bind(this);
        this.loadListInvoiceByInvoice = this.loadListInvoiceByInvoice.bind(this);
        this.loadAccounts = this.loadAccounts.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmPay = this.confirmPay.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    }

    componentDidMount() {
        this.loadListInvoiceByInvoice();
        this.loadAccounts();
        localStorage.removeItem('saved_path');
    }

    loadAccounts = () => {
        const self = this;
        loadProfile(self, self.backendService, null).then(resp => {
            if (resp !== null) {
                const accounts = resp.array_accounts;
                accounts.forEach(element => {
                    element.label = element.label + " / $" + element.last_balance;
                });
                const sender_account = accounts.length > 0 ? accounts[0] : ';'
                this.setState({accounts, sender_account});
            }
        });
    };

    loadListInvoiceByInvoice(){
        const {invoiceNumber} = this.state;

        const params = {invoice: invoiceNumber, email: localStorage.getItem('userNameSesion')};
        this.backendService.getListInfoByInvoice(params).then(resp => {
            if(resp.status === "success"){
                this.setState({listInvoice: resp.data});
            }
        }).catch(error => {
            if (this.growl !== null) {
                this.growl.show({severity: 'error', summary: 'Error', detail: "Invoice Payment not found"});
            }
        });
    }

    searchInvoice = e => {
        e.preventDefault();
        const {
            invoiceNumber
        } = this.state;
        const param = {invoiceNumber};
        const type = "searchInvoice";
        const errors = this.validateForm(param, type);
        if (Object.keys(errors).length === 0) {
            this.loadListInvoiceByInvoice();
        } else {
            this.setState({errors});
        }
    }

    validateForm = (data, type) => {
        let errors = {};
        switch (type) {
            case "searchInvoice":
                if (!data.invoiceNumber) errors.invoiceNumber = 'Invoice Number is mandatory.';
                break;
            default:
                break
        }

        return errors;
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    }

    showModal = (modal, row) => () => {
        this.setState({[modal]: true});
        if (row !== undefined && row !== null) {
            this.setState({dataTableSelection: row});
        }
    }

    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }

    confirmationDialog = (nameModal, showModal, action) => {
        const {dataTableSelection, sender_account} = this.state;
        if(nameModal === "detailInvoiceDisplay"){
            this.setState({[showModal]: false, [nameModal]: false})
            return
        }
        if (Object.keys(dataTableSelection).length === 0 || sender_account === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Select an account'});
        } else {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        }
    }

    confirmPay = e => {
        e.preventDefault();
        const {dataTableSelection, sender_account} = this.state;
        console.log(Object.keys(dataTableSelection).length)
        if (Object.keys(dataTableSelection).length === 0 || sender_account === '') {
            this.growl.show({severity: 'error', summary: 'Error', detail: 'Select an account'});
        } else {
            this.setState({displayConfirmSend: true})
            const profileData = JSON.parse(localStorage.getItem('profile'));
            const params = {
                account_id: sender_account.provider_accountid,
                invoice_id: dataTableSelection.id.toString(),
                username : profileData.email,
            }
            this.backendService.paymentInvoice(params).then(resp => {
                if (resp.status === 'success') {
                    this.growl.show({
                        severity: 'success',
                        summary: 'Success',
                        detail: "Payment Invoice"
                    });
                    this.setState({
                        listInvoice: [],
                        confirmPayDisplay: false,
                        displayConfirm: false,
                        displayConfirmSend: false,
                        //displayLinkToPay: true,
                        linkToPay:resp.data?.odoo?.responseUrl
                    });
                    this.loadListInvoiceByInvoice();
                }
            }).catch(error => {
                this.setState({displayConfirmSend: false})
                console.log("ERROR: ", error);
                if (error.response.status === 401) {
                    logOut(this.props.history);
                } else if (this.growl !== null) {
                    const msg = error.response.data?.message !== undefined ? error.response.data?.message : "Service error to pay";
                    this.growl.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: msg
                    });
                }
            });
        }
    }

    actionBodyTemplate(row) {
        return (
            <React.Fragment>
                <div className="p-grid">
                    <div className="p-col-6">
                        <Button label="Pay"
                                onClick={this.showModal('confirmPayDisplay')}
                                className="p-button-warning"/>
                    </div>
                    <div className="p-col-6">
                        <Button label="Detail"
                                onClick={this.showModal('detailInvoiceDisplay', row)}
                                className="p-button-warning"/>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.displayConfirmSend}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.displayConfirmSend}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?"
                    visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Yes, continue</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const payDialog = (
            <Dialog header="Select Account" visible={this.state.confirmPayDisplay} modal={true}
                    style={{width: '500px', background: "#1c2155"}}
                    contentStyle={this.state.sender_account?.last_balance < this.state.dataTableSelection?.amount ? {height: 350} : {height: 250}}
                    footer={dialogFooter('confirmPayDisplay', this.confirmPay, 'Pay')}
                    onHide={this.hideModal('confirmPayDisplay')}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <Dropdown
                            scrollHeight="100px"
                            options={this.state.accounts}
                            optionLabel="label"
                            value={this.state.sender_account}
                            placeholder="Select Account"
                            name="sender_account"
                            onChange={this.changeHandler} autoWidth={false}/>
                    </div>
                    {this.state.sender_account?.last_balance < this.state.dataTableSelection?.amount &&
                        <div className="p-col-12">
                            <span className="text-big">
                                You do not have enough funds, do you want to top up your account now?
                            </span>
                            <Button label="Top up"
                                    onClick={() => this.props.history.push('/top_up_account')}/>
                        </div>
                    }
                </div>
            </Dialog>
        );

        const detailInvoice = (
            <Dialog header="Detail Invoice" visible={this.state.detailInvoiceDisplay} modal={true}
                    style={{width: '800px', background: "#1c2155"}} contentStyle={{height: 300}}
                    footer={dialogFooter('detailInvoiceDisplay', this.hideModal('detailInvoiceDisplay'), 'Hide')}
                    onHide={this.hideModal('detailInvoiceDisplay')} maximizable>
                <div className="p-grid">
                    <div className="p-col-12">
                        <table className="custom_table" style={{width:'100%'}}>
                            <tbody>
                            <tr className="head">
                                <td>ID: </td>
                                <td>{this.state.dataTableSelection.id}</td>
                                <td>Status:</td>
                                <td>{this.state.dataTableSelection.status}</td>
                                <td>Status Payment:</td>
                                <td>{this.state.dataTableSelection.status_payment}</td>
                            </tr>
                            <tr className="head">
                                <td>First Name:</td>
                                <td>{this.state.dataTableSelection.first_name}</td>
                                <td>Last Name:</td>
                                <td>{this.state.dataTableSelection.last_name}</td>
                                <td>Email:</td>
                                <td>{this.state.dataTableSelection.email}</td>
                            </tr>
                            <tr className="head">
                                <td>Currency:</td>
                                <td>{this.state.dataTableSelection.currency}</td>
                                <td>Amount:</td>
                                <td>{this.state.dataTableSelection.amount}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr className="head">
                                <td>Phone:</td>
                                <td>{this.state.dataTableSelection.phone}</td>
                                <td>Invoice Number:</td>
                                <td>{this.state.dataTableSelection.invoice_number}</td>
                                <td>Type:</td>
                                <td>{this.state.dataTableSelection.type}</td>
                            </tr>
                            <tr className="head">
                                <td>Comments:</td>
                                <td>{this.state.dataTableSelection.comments}</td>
                                <td>Created:</td>
                                <td>{this.state.dataTableSelection.dcreated}</td>
                                <td>Ecommerce Number:</td>
                                <td>{this.state.dataTableSelection.ecommerce_number}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </Dialog>
        );

        const invoices = (
            <div className="p-col-12">
                <DataTable value={this.state.listInvoice} selectionMode="single" paginator={true} rows={10}
                           selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                           scrollable
                           responsive={true}>
                    <Column field='type' header='Type' className="ta-center"/>
                    <Column field='email' header='Email' className="ta-center"/>
                    <Column field='amount' header='Amount' className="ta-center"/>
                    <Column field='currency' header='Currency' className="ta-center"/>
                    <Column field='comments' header='Comments' className="ta-center"/>
                    <Column field='status' header='Status' className="ta-center"/>
                    <Column body={this.actionBodyTemplate} header='Actions' style={{width:'210px'}} className="ta-center"/>
                </DataTable>
            </div>
        );

        const showLinkToPayDialog = (
            <Dialog header="View Order Details"
                    visible={this.state.displayLinkToPay} modal={true}
                    style={{width: '400px', background: "#1c2155"}}
                    contentStyle={{height: 80}}
                    onHide={() => {
                        window.open(this.state.linkToPay, "_ linkBankAccount",
                            "width=800,height=600, toolbar=no, location=no,top=70,left=100")
                        this.setState({displayLinkToPay: false, displayConfirm: false});
                    }}>
                <div className="p-grid">
                    <div className="p-col-6 p-offset-3">
                        <Button label="View Receipt"
                                onClick={() => {
                                    window.open(this.state.linkToPay, "_ linkBankAccount",
                                        "width=800,height=600, toolbar=no, location=no,top=70,left=100")
                                    this.setState({displayLinkToPay: false, displayConfirm: false});
                                }}/>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="p-grid p-fluid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h2 className="mb-20">Invoices Payment</h2>
                            </div>
                            <div className="p-col-4">
                                <InputText
                                    className="pd-11"
                                    placeholder="Invoice number"
                                    name="invoiceNumber"
                                    onChange={this.changeHandler}
                                    value={this.state.invoiceNumber}
                                />
                                {this.state.errors?.invoiceNumber !== undefined ?
                                    <small
                                        className="p-messages p-messages-error">{this.state.errors?.invoiceNumber}</small>
                                    : ""
                                }
                            </div>
                            <div className="p-col-4">
                                <Button label="Search" icon="pi pi-search" style={{marginBottom: '10px'}} onClick={this.searchInvoice}/>
                            </div>
                            {invoices}
                        </div>
                    </div>
                </div>
                {payDialog}
                {confirmDialog}
                {showLinkToPayDialog}
                {detailInvoice}
                <ValidationKYC history={this.props.history}/>
            </div>
        );
    }
}
