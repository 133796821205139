import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Messages} from "primereact/messages";
import {Button} from "primereact/button";
import {hideModal, logOut, showModal} from "../../util/commonFunctions";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";

export class Token extends Component {
    constructor() {
        super();
        this.state = {
            tokens: [],
            dataTableSelection: {},
            actionToConfirm: null,
            displayConfirm: false,
            createTokenDialogDisplay: false,
            username: "",
            expire: null,
            minDate: new Date(),
            errors: {},
        }
        this.backendService = new BackendService();
        this.loadTokens = this.loadTokens.bind(this);
        this.createTokenSubmitHandler = this.createTokenSubmitHandler.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.revokeTokenSubmitHandler = this.revokeTokenSubmitHandler.bind(this);
    }

    componentDidMount() {
        this.loadTokens();
    }

    loadTokens() {
        const tokens = [];
        this.backendService.getTokens().then(resp => {
            if (resp["status code"] === 200) {
                if (resp.data) {
                    resp.data.forEach(element => {
                        element.expires = element.expires.substring(0, 10);
                        tokens.push(element)
                    });
                    this.setState({tokens})
                    this.messages.show({severity: 'success', summary: 'Success', detail: `Tokens ${tokens.length}`});
                }
            }
        }).catch(error => {
            try {
                if (error.response.status === 401) {
                    logOut(this.props.history);
                } else if (this.messages !== null) {
                    this.messages.show({severity: 'error', summary: 'Error', detail: `Get tokens: ${error.message}`});
                }
            } catch (e) {
                console.error("Error:", e)
            }
        });
    }

    createTokenSubmitHandler = e => {
        e.preventDefault();
        if (this.state.username === '' && this.state.expire !== null) {
            this.messages.show({severity: 'error', summary: 'Error', detail: 'Please give a valid info!'});
        } else {
            const params = {
                username: this.state.username,
                expire: this.state.expire
            }
            this.backendService.createToken(params).then(resp => {
                this.setState({
                    username: '',
                    expire: null,
                    createTokenDialogDisplay: false,
                    displayConfirm: false,
                });
                this.messages.show({severity: 'success', summary: 'Success', detail: 'New token added successfully!'});
                this.loadTokens();
            }).catch(error => {
                try {
                    if (error.response.status === 401) {
                        logOut(this.props.history);
                    } else if (this.messages !== null) {
                        this.messages.show({severity: 'error', summary: 'Error', detail: `Error load profile`});
                    }
                } catch (e) {
                    console.error("Error:", e)
                }
            });
        }
    };

    revokeTokenSubmitHandler = e => {
        e.preventDefault();
        const {dataTableSelection} = this.state;

        const params = {
            token: dataTableSelection.token
        }
        this.backendService.revokeToken(params).then(resp => {
            this.setState({displayConfirm: false});
            this.messages.show({severity: 'success', summary: 'Success', detail: 'Token revoked!'});
        }).catch(error => {
            try {
                if (error.response.status === 401) {
                    logOut(this.props.history);
                } else if (this.messages !== null) {
                    this.messages.show({severity: 'error', summary: 'Error', detail: `Error load profile`});
                }
            } catch (e) {
                console.error("Error:", e)
            }
        });
    };

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    };

    confirmationDialog = (nameModal, showModal, action) => {
        this.setState({
            [showModal]: true,
            actionToConfirm: action
        })
    }

    actionBodyTemplate() {
        return (
            <React.Fragment>
                <Button label="Revoke"
                        onClick={showModal('displayConfirm', this, this.revokeTokenSubmitHandler)}
                        className="p-button-warning"/>
            </React.Fragment>
        )
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={hideModal(nameModal, this)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw', background: "#1c2155"}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={hideModal('displayConfirm', this)}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={hideModal(nameModal, this)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction}/>
            </div>
        );

        const createTokenDialog = (
            <Dialog header="New Token" visible={this.state.createTokenDialogDisplay} modal={true}
                    style={{width: '450px', background: "#1c2155"}}
                    contentStyle={{height: 350}}
                    footer={dialogFooter('createTokenDialogDisplay', this.createTokenSubmitHandler, 'New')}
                    onHide={hideModal('createTokenDialogDisplay', this)}>
                <div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="expire" className="p-col-fixed" style={{width: '120px'}}>Expire:</label>
                        <div className="p-col">
                            <Calendar id="expire"
                                      placeholder="Expire"
                                      dateFormat="yy-mm-dd"
                                      name="expire"
                                      value={this.state.expire}
                                      onChange={this.changeHandler}
                                      showIcon monthNavigator yearNavigator yearRange="2022:2030"
                                      minDate={this.state.minDate} />
                        </div>
                    </div>
                    <div className="p-field p-grid p-fluid">
                        <label htmlFor="amount" className="p-col-fixed" style={{width: '120px'}}>Username:</label>
                        <div className="p-col">
                            <InputText value={this.state.username}
                                       placeholder="Username"
                                       name="username"
                                       onChange={this.changeHandler}
                                       style={{padding: 9}}
                            />
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-3">
                                <h2 className="mb-20">Token</h2>
                            </div>
                            <div className="p-col-9 text-right">
                                <Button label="Create" onClick={showModal('createTokenDialogDisplay', this)}/>
                            </div>
                            <div className="p-col-12">
                                <Messages ref={(el) => this.messages = el}/>
                            </div>
                            <div className="p-col-12">
                                <DataTable value={this.state.tokens} selectionMode="single" paginator={true} rows={10}
                                           selection={this.state.dataTableSelection}
                                           onSelectionChange={event => this.setState({dataTableSelection: event.value})}
                                           responsive={true}>
                                    <Column field='token' header='Token'/>
                                    <Column field='expires' header='Expires' className="ta-center"/>
                                    <Column field='scope' header='Scope' className="ta-center"/>
                                    <Column body={this.actionBodyTemplate} header='Actions' style={{width: '210px'}}
                                            className="ta-center"/>
                                </DataTable>
                            </div>
                            {confirmDialog}
                            {createTokenDialog}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
