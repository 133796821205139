import React, {Component} from "react";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";
import {Chart} from "primereact/chart";
import {getMonths, months} from "../../util/commonFunctions";
import {Balance} from "../MainDashboard/Balance";
import {ValidationKYC} from "../KYC/ValidationKYC";

export class AccountActivity extends Component {
    constructor() {
        super();
        this.state = {
            depositTransferData: null,
            totalBalance: localStorage.getItem("totalBalance"),
        }
        this.options = this.getLightTheme();
        this.backendService = new BackendService();
    }

    componentDidMount() {
        const profile = JSON.parse(localStorage.getItem('profile'));
        const arrayAccounts = profile.array_accounts;
        if (arrayAccounts.length > 0) {
            const accountID = arrayAccounts[0].provider_accountid;

            this.backendService.postReportHistory({
                account_id: accountID,
                date_ini: '',
                date_end: '',
            }).then(resp2 => {
                if (resp2.status === 'success') {
                    const lstDeposit = resp2.data.lstDeposit;
                    const lstWithdraw = resp2.data.lstWithdraw;
                    const totalData = resp2.data.total;
                    const depositeData = [0,0,0,0,0,totalData.totalPayments];
                    const withdrawData = [0,0,0,0,0,totalData.totalWithdraw];
                    const labels = getMonths(6);

                    const monthHistoryData={
                        labels,
                        datasets: [
                            {
                                label: 'Deposit',
                                backgroundColor: '#EDEDED',
                                data: depositeData
                            },
                            {
                                label: 'Transfer',
                                backgroundColor: '#E0C46C',
                                data: withdrawData
                            }
                        ]
                    };

                    const depositTransferData={
                        labels: months,
                        datasets: [
                            {
                                label: 'Deposit',
                                data: lstDeposit,
                                fill: false,
                                borderColor: '#EDEDED'
                            },
                            {
                                label: 'Transfer',
                                data: lstWithdraw,
                                fill: false,
                                borderColor: '#E0C46C'
                            }
                        ]
                    };

                    this.setState({
                        monthHistoryData,
                        depositTransferData
                    });
                }
            }).catch(error => {
                console.error("error load account: ",error)
                if (this.growl !== null) {
                    this.growl.show({severity: 'error', summary: 'Error', detail: "Can not load account/history/"});
                }
            });
        }
    }

    getLightTheme() {
        let basicOptions = {
            legend: {
                labels: {
                    fontColor: '#E0C46C'
                }
            },
            scales: {
                xAxes: [{
                    ticks: {
                        fontColor: '#E0C46C'
                    },
                    gridLines: {
                        color: "#E0C46C",
                        lineWidth: 1,
                        zeroLineColor: "#E0C46C",
                        zeroLineWidth: 1
                    },
                }],
                yAxes: [{
                    ticks: {
                        fontColor: '#E0C46C'
                    },
                    gridLines: {
                        color: "#E0C46C",
                        lineWidth: 1,
                        zeroLineColor: "#E0C46C",
                        zeroLineWidth: 1
                    },
                }]
            },

        };
        let lightOptions = {
            legend: {
                labels: {
                    fontColor: '#E0C46C'
                }
            }
        };

        return {
            lightOptions,
            basicOptions
        }
    }

    render() {
        return (
            <div className="p-grid p-fluid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8 p-md-8">
                                <h1>Account Activity</h1>
                            </div>
                        </div>
                    </div>
                    <div className="card card-w-title datatable-demo">
                        <div className="p-grid">
                            <div className="p-col-12 p-lg-8 p-md-8">
                                <h2 className="mb-20">Last 6 month history</h2>
                            </div>
                            <div className="p-col-12 p-lg-4 p-md-4">
                                <Balance totalBalance={this.state.totalBalance} />
                            </div>
                            <div className="p-col-12">
                                <div className="p-col-6">
                                    <h2>Deposit & transfer</h2>
                                </div>
                                {this.state.depositTransferData !== null &&
                                    <div className="p-col-12">
                                        <Chart type="line" data={this.state.depositTransferData} options={this.options.basicOptions} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <ValidationKYC history={this.props.history}/>
            </div>
        )
    }

}
