import React, {Component} from 'react';
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import {ProgressSpinner} from "primereact/progressspinner";
import {InputText} from "primereact/inputtext";
import {Password} from "primereact/password";
import {BackendService} from "../../service/wallet_backend/BackendService";
import {Growl} from "primereact/growl";

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: true,
            displayConfirm: false,
            actionToConfirm: null,
            processService: false,

            password: '',
            newPassword: '',
            confirmPassword: '',
            updated: false,
            errors: {},
        }
        this.backendService = new BackendService();
        this.submitHandler = this.submitHandler.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
    }
    componentDidMount() {
    }

    confirmationDialog = (showModal, action) => {
        const params = {
            password: this.state.password,
            newPassword: this.state.newPassword,
            confirmPassword: this.state.confirmPassword
        };
        const errors = this.validate(params);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            });
        } else {
            this.setState({errors});
        }
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false,
        });
    }

    submitHandler = () => {
        const {
            password,
            newPassword,
            confirmPassword
        } = this.state;
        const errors = {};
        this.setState({updated: true});
        if(newPassword === confirmPassword){
            const params = {
                old_password: password,
                new_password1: newPassword,
                new_password2: confirmPassword
            };
            this.backendService.postChangePassword(params).then(resp => {
                if(resp["status code"] === 200){
                    this.growl.show({severity: 'success', summary: 'Success', detail: "Your password was updated"});
                    this.setState({
                        password: '',
                        newPassword: '',
                        confirmPassword: ''
                    })
                }
            }).catch(error => {
                this.setState({updated: false});
                if(error.response?.data["status code"] === 409){
                    errors.serviceError = error.response.data.message?.password_mismatch + "\n" + error.response.data.message?.password_incorrect;
                    this.setState({errors})
                    this.growl.show({severity: 'error', summary: 'Error on service', detail: errors.serviceError});
                } else {
                    this.growl.show({severity: 'error', summary: 'Error on service', detail: error.message});
                }
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({updated: false});
            errors.newPassword = 'New password is not equals to confirm.';
            errors.confirmPassword = 'Confirm password is not equals to new password.';
        }
        this.setState({errors, displayConfirm: false});
    }

    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({[event.target.name]: event.target.value, errors});
    }

    validate = (data) => {
        let errors = {};
        if (!data.password) {
            errors.password = 'Password is mandatory.';
        }
        if (!data.newPassword) {
            errors.newPassword = 'New password is mandatory.';
        }
        if (!data.confirmPassword) {
            errors.confirmPassword = 'Confirm password is mandatory.';
        }
        return errors;
    }

    render() {
        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processService}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processService}/>
            </div>
        )
        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        )

        return (
            <div className="pageWrapper">
                <Growl ref={(el) => this.growl = el} />
                <div className="grid">
                    {
                        this.state.chargedYet ? '' :
                            <div className="col-9 ta-right" style={{ position: "absolute", marginTop: 0 }}>
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} animationDuration=".5s" />
                            </div>
                    }
                </div>
                <div className="p-col-12">
                    <div className="card" style={{padding: 40}}>
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <h1>Reset profile</h1>
                                </div>
                                <div className="p-col-12 p-field p-grid p-fluid">
                                    <div className="p-col-12 p-lg-4 p-md-4">
                                        <label>Current Password</label>
                                        <InputText
                                            className={this.state.errors?.password !== undefined ? "p-invalid pd-11" : "pd-11"}
                                            placeholder="Password"
                                            name="password"
                                            type="password"
                                            onChange={this.changeHandler}
                                            value={this.state.password}
                                            disabled={this.state.updated}
                                        />
                                        {this.state.errors?.password !== undefined ?
                                            <small className="offline">{this.state.errors?.password}</small>
                                            : ""
                                        }
                                    </div>

                                    <div className="p-col-12 p-lg-4 p-md-4">
                                        <label>New Password</label>
                                        <Password
                                            toggleMask
                                            className={this.state.errors?.newPassword !== undefined ? "p-invalid pd-11" : "pd-11"}
                                            placeholder="New password"
                                            name="newPassword"
                                            value={this.state.newPassword}
                                            onChange={this.changeHandler}
                                            disabled={this.state.updated}
                                        />
                                        {this.state.errors?.newPassword !== undefined ?
                                            <small className="offline">{this.state.errors?.newPassword}</small>
                                            : ""
                                        }
                                    </div>

                                    <div className="p-col-12 p-lg-4 p-md-4">
                                        <label>Confirm New Password</label>
                                        <InputText
                                            className={this.state.errors?.confirmPassword !== undefined ? "p-invalid pd-11" : "pd-11"}
                                            placeholder="Confirm password"
                                            name="confirmPassword"
                                            type="password"
                                            onChange={this.changeHandler}
                                            value={this.state.confirmPassword}
                                            disabled={this.state.updated}
                                        />
                                        {this.state.errors?.confirmPassword !== undefined ?
                                            <small className="offline">{this.state.errors?.confirmPassword}</small>
                                            : ""
                                        }
                                    </div>

                                    {this.state.errors?.serviceError !== undefined ?
                                        <div className="p-col-12">
                                            <small className="offline">{this.state.errors?.serviceError}</small>
                                        </div>
                                        : ""
                                    }

                                </div>
                                <div className="p-col-6 p-offset-6">
                                    <Button
                                        label="Submit"
                                        className="p-button-rounded p-button-primary"
                                        style={{marginBottom: '10px'}}
                                        disabled={this.state.updated}
                                        onClick={(e) => this.confirmationDialog('displayConfirm', this.submitHandler)}
                                    />
                                </div>
                            </div>
                    </div>
                </div>
                {confirmDialog}
                {renderFooterConfirm}
            </div>
        )
    }
}

export default ResetPassword;
