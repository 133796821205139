import React, {Component} from 'react';
import {changeStyleSheetUrl} from "../util/commonFunctions";
import {Card} from "primereact/card";

export default class Privacy_policy extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'blue-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'blue-v4', 'theme');
    }

    sendLogin = () => {
        this.props.history.push("/login");
    }


    render() {
        return (
            <Card>
                <div style={{textAlign: 'justify', color: 'white', backgroundColor: "#223035",paddingLeft: "20px",
                    paddingRight: "20px",
                    paddingTop: "20px",
                    paddingBottom: "20px"}}>
                    <h2 style={{color: "#E0C46C"}}>Privacy Policy</h2>
                    <h1 style={{color: "#E0C46C"}}>Effective: November 1, 2022</h1>
                    This privacy policy (the “Privacy Policy”) applies to the GoStax application (the
                    “App”) and our website, located at www.gostax.com (the “Site”), owned and
                    operated by Go Stax Group, LLC (“GoStax,” “we,” “us,” or “our”). We have created
                    this Privacy Policy to tell you what information the App and our Site collect, how
                    we use that information, and who we will share that information with, if at all. This
                    Privacy Policy does not address the privacy practices of any third parties that we
                    do not own, control, or are affiliated with. Capitalized terms not defined in this
                    Privacy Policy will have the meaning set forth in our Terms of Use. By visiting
                    and/or using our App or Site, you are agreeing to the terms of this Privacy Policy
                    and the accompanying Terms of Use. We encourage you to read the Privacy Policy,
                    and to use the information it contains to help you make informed decisions.
                    <h1 style={{color: "#E0C46C"}}>A. Information We Collect or Receive</h1>

                    <b style={{color: "#E0C46C"}}>1. Personal Information.</b>
                    <p/>
                    We only receive or collect information that identifies you
                        personally if you choose to provide such personally identifiable information to us
                        via email or other means. When you sign up to become a user or contact us, you
                        will be required to provide us with personal information about yourself
                        (collectively, the “Personal Information”). Such Personal Information may include
                        your name, e-mail address, physical address, and phone number. We do not collect
                        any Personal Information from you when you use the App unless you provide us
                        with the Personal Information voluntarily.
                    <p/>
                    <b style={{color: "#E0C46C"}}>2. Third-Party Log In.</b>
                    <p/>
                    If you sign in through the Site, you are authorizing us to
                        collect, store, and use, in accordance with this Privacy Policy, any and all
                        information that you agreed that any applicable third party would provide to us
                        through GoStax’s Application Programming Interface (“API”). Such information
                        may include, without limitation, your first and last name, GoStax username, GoStax
                        profile picture, headline, unique identifier and access token, and e-mail address.

                    <p/>
                    <b style={{color: "#E0C46C"}}>3. Payment Information.</b>
                    <p/>
                    If you choose to make a purchase or subscribe to a
                        feature or service of ours that requires a fee, you will be required to provide us
                        with your payment information, including, without limitation, bank account
                        numbers, credit card or debit card numbers, account details, ACH information, and
                        similar data (collectively, “Payment Information”). Such Payment Information will
                        be collected and processed by our third-party payment vendors, and in some cases
                        by us, pursuant to the terms and conditions of their privacy policies and terms of
                        use. In the event we process your payment, to the extent we collect information to
                        verify we your identity, we may use and share your information with third-parties
                        to verify your identity, and such information collection will be governed by their
                        respective privacy policies and terms of use.
                    <p/>
                    <b style={{color: "#E0C46C"}}>4. Device Phonebook. </b>
                    <p/>
                    Because our Services allow you to communicate with
                        friends, we may collect information from your device’s phonebook if you provide
                        permission to do so.
                    <p/>
                    <b style={{color: "#E0C46C"}}>5. Profile Pictures.</b>
                    <p/>
                    Because our Services allow you to set a profile photo, we may
                        collect your contact picture from your phonebook for such photo.
                    <p/>
                    <b style={{color: "#E0C46C"}}>6. Information related to Third Party Integrations.</b>
                    <p/>
                    GoStax may collect information
                        about you from third parties to help improve our service, to verify your identity, or
                        for security and support reasons. If you choose to use some of the optional
                        integrations the third party services that we offer may collect information about
                        you from those services. For example, if you choose to send payments to your
                        friends using one of the payment services we integrate with we may collect
                        information from that payment service (i.e., your username for that service, bank
                        account information, status of payment, etc.).
                    <p/>
                    <b style={{color: "#E0C46C"}}>7. Geolocational Information.</b>
                    <p/>
                    Certain features and functionalities of the App are
                        based on your location. In order to provide these features and functionalities while
                        you are using your mobile device, we may, with your consent, automatically collect
                        geolocational information from your mobile device or wireless carrier and/or
                        certain third-party service providers (collectively, “Geolocational Information”).
                        Collection of such Geolocational Information occurs only when the App is running
                        on your device. You may decline to allow us to collect such Geolocational
                        Information, in which case we will not be able to provide certain features or
                        functionalities to you.
                    <p/>
                    <b style={{color: "#E0C46C"}}>8. Other Information. </b>
                    <p/>
                    In addition to the Personal Information, Payment
                        Information, and Geolocational Information, we may automatically collect or
                        receive additional information regarding you and your use of the App; your
                        interactions with us and our advertising; and information regarding your computer
                        and mobile devices used to access the App (collectively, the “Other Information”).
                     Such Other Information may include:
                    <p/>
                    <b style={{color: "#E0C46C"}}>1. From You.</b>
                    <p/>
                    Additional information about yourself that you voluntarily provide to
                        us, such as your gender and your product and service preferences.
                    <p/>
                    <b style={{color: "#E0C46C"}}>2. From Your Activity.</b>
                    <p/>
                   We may collect or receive information regarding:
                    <p/>
                    1.) IP address, which may consist of a static or dynamic IP address and will
                    sometimes point to a specific identifiable computer or mobile device;
                    <p/>
                    2.) browser type and language;
                    <p/>
                    3.) referring and exit pages and URLs;
                    <p/>
                    4.) date and time; and
                    <p/>
                    5.) details regarding your activity on the App, such as search queries and other
                    performance and usage data.
                    <p/>
                    3. About Your Mobile Device. We may collect or receive information regarding:
                    <p/>
                    1.) type of mobile device;
                    <p/>
                    2.) advertising Identifier (“IDFA” or “AdID”);
                    <p/>
                    3.) operating system and version (e.g., iOS, Android or Windows);
                    <p/>
                    4.) carrier; and
                    <p/>
                    5.) network type (WiFi, 3G, 4G, LTE).
                    <p/>
                    4. From Cookies. We may use both session cookies, which expire once you close
                    the App, and persistent cookies, which stay on your mobile device until you delete
                    them and other technologies to help us collect data and to enhance your
                    experience with the App. Cookies are small text files an app can use to recognize a
                    repeat visitor to the app. We may use cookies for various purposes, including to:
                    <p/>
                    1.) type of mobile device;
                    <p/>
                    2.) personalize your experience;
                    <p/>
                    3.) analyze which portions of the App are visited and used most frequently; and
                    <p/>
                    4.) measure and optimize advertising and promotional effectiveness.
                    <p/>

                    If you do not want us to deploy cookies in the App, you can opt out by setting your
                    mobile device to reject cookies. You can still use the App if you choose to disable
                    cookies, although your ability to use some of the features may be affected.
                    <p/>
                    <h1 style={{color: "#E0C46C"}}>B. Information Collected by Or Through Third-Party Advertising
                        Companies.</h1>
                    1. You authorize us to use the Personal Information, Payment Information,
                    Geolocational Information, and the Other Information (collectively, the
                    “Information”) to:
                    <p/>
                    1.) provide and improve our App;
                    <p/>
                    2.) provide our services;
                    <p/>
                    3.) administer our promotional programs;
                    <p/>
                    4.) solicit your feedback; and
                    <p/>
                    5.) inform you about our products and services.
                    <p/>

                    2. In order to provide our services and administer our promotional programs, we
                    may share the Information with our third-party promotional and marketing partners,
                    including, without limitation, businesses participating in our various programs.
                    <p/>
                    3. We engage third-party companies and individuals to perform functions on our
                    behalf. Examples may include providing technical assistance, customer service,
                    marketing assistance, and administration of promotional programs. These other
                    companies will have access to the Information only as necessary to perform their
                    functions and to the extent permitted by law.
                    <p/>
                    4. In an ongoing effort to better understand our users, the App, and our products
                    and services, we may analyze certain Information in anonymized and aggregate form
                    to operate, maintain, manage, and improve the App and/or such products and
                    services. This aggregate information does not identify you personally. We may share
                    and/or license this aggregate data to our affiliates, agents, business and promotional
                    partners, and other third parties. We may also disclose aggregated user statistics to
                    describe the App and these products and services to current and prospective
                    business partners and investors and to other third parties for other lawful purposes.
                    <p/>
                    5. We may share some or all of your Information with any of our parent companies,
                    subsidiaries, joint ventures, or other companies under common control with us.
                    <p/>
                    6. As we develop our businesses, we might sell or buy businesses or assets. In the
                    event of a corporate sale, merger, reorganization, sale of assets, dissolution, or
                    similar event, the Information may be part of the transferred assets.
                    <p/>
                    7. To the extent permitted by law, we may also disclose the Information:
                    <p/>
                    1.) when required by law, court order, or other government or law enforcement
                    authority or regulatory agency; or
                    <p/>
                    2.) whenever we believe that disclosing such Information is necessary or advisable,
                    for example, to protect the rights, property, or safety of us or others, including you.
                    <p/>
                    We will use your Payment Information to process your authorized payments through
                    the App. We will share your Payment Information with a third-party service provider
                    as necessary to process your payment. The third-party service provider stores your
                    Payment Information; we do not store this information. Storage by the third-party
                    service provider of your Payment Information is subject to the privacy policies and
                    practices of the third-party service provider and is not subject to the terms of this
                    Privacy Policy. By providing your Payment Information, you acknowledge and agree
                    to use of such information by the third-party service provider for purposes of
                    processing your payment to us.
                    <p/>
                    <h1 style={{color: "#E0C46C"}}>C. Accessing and Modifying Information and Communication Preferences</h1>
                    If you have provided us any Personal Information, you may access, remove, review,
                    and/or make changes to the same by contacting us as set forth below. In addition,
                    you may manage your receipt of marketing and non-transactional communications
                    by clicking on the “unsubscribe” link located on the bottom of any of our marketing
                    e-mails. We will use commercially reasonable efforts to process such requests in a
                    timely manner. You should be aware, however, that it is not always possible to
                    completely remove or modify information in our subscription databases. You cannot
                    opt out of receiving transactional e-mails related to the App (e.g., requests for
                    support).
                    <p/>
                    We may also deliver notifications to your mobile device (e.g., push notifications). You
                    can disable these notifications by deleting the relevant service or by changing the
                    settings on your mobile device.
                    <h1 style={{color: "#E0C46C"}}>D. How We Protect Your Information.</h1>
                    We take commercially reasonable steps to protect the Information from loss, misuse,
                    and unauthorized access, disclosure, alteration, or destruction. Please understand,
                    however, that no security system is impenetrable. We cannot guarantee the security
                    of our databases or the databases of the third parties with which we may share such
                    Information, nor can we guarantee that the Information you supply will not be
                    intercepted while being transmitted over the Internet. In particular, e-mail sent to us
                    may not be secure, and you should therefore take special care in deciding what
                    information you send to us via e-mail.
                    <h1 style={{color: "#E0C46C"}}>E. Important Notices to Non-U.S. Residents.</h1>
                    The App and its servers are operated in the United States. If you are located outside
                    of the United States, please be aware that your Information, including your Personal
                    Information, may be transferred to, processed, maintained, and used on computers,
                    servers, and systems located outside of your state, province, country, or other
                    governmental jurisdiction where the privacy laws may not be as protective as those
                    in your jurisdiction. If you are located outside the United States and choose to use
                    the App, you hereby irrevocably and unconditionally consent to such transfer,
                    processing, and use in the United States and elsewhere.
                    <h1 style={{color: "#E0C46C"}}>F. App Stores; External Websites.</h1>
                    Your app store (e.g., iTunes or Google Play) may collect certain information in
                    connection with your use of the App, such as Personal Information, Payment
                    Information, Geolocational Information, and other usage-based data. We have no
                    control over the collection of such information by a third-party app store, and any
                    such collection or use will be subject to that third party’s applicable privacy policies.
                    <p/>
                    The App may contain links to third-party websites. We have no control over the
                    privacy practices or the content of these websites. As such, we are not responsible
                    for the content or the privacy policies of those third-party websites. You should
                    check the applicable third-party privacy policy and terms of use when visiting any
                    other websites.
                    <h1 style={{color: "#E0C46C"}}>G. Children.</h1>
                    The App and the Site are not directed to minors under the age of 18. We adhere to
                    the Children’s Online Privacy Protection Act (COPPA) and will not knowingly collect
                    Personal Information from any child under the age of 18. We ask that minors (under
                    the age of 18) not use the App. If a child under the age of 18 has provided us with
                    Personal Information, a parent or guardian of that child may contact us and request
                    that such information be deleted from our records.
                    <h1 style={{color: "#E0C46C"}}>H. California Privacy Rights: Shine the Light Law.</h1>
                    Under California Civil Code Section 1798.83, California residents who have an
                    established business relationship with us may choose to opt out of our sharing their
                    contact information with third parties for direct marketing purposes. If you are a
                    California resident and you wish to opt out, please send an e-mail to
                    support@gostax.com.
                    <p/>
                    <h1 style={{color: "#E0C46C"}}>I. California Privacy Law: Do Not Track.</h1>
                    We do not monitor, recognize, or honor any opt-out or do not track mechanisms,
                    including general web browser “Do Not Track” settings and/or signals.
                    <h1 style={{color: "#E0C46C"}}>J. Changes to This Privacy Policy.</h1>


                    This Privacy Policy is effective as of the date stated at the top of this Privacy Policy.
                    We may change this Privacy Policy from time to time. Any such changes will be
                    posted on the App. By accessing the App after we make any such changes to this
                    Privacy Policy, you are deemed to have accepted such changes. Please be aware
                    that, to the extent permitted by applicable law, our use of the Information is
                    governed by the Privacy Policy in effect at the time we collect the Information.
                    Please refer back to this Privacy Policy on a regular basis.
                    <h1 style={{color: "#E0C46C"}}>K. How to Contact Us.</h1>
                    If you have questions about this Privacy Policy, please e-mail us at
                    support@gostax.com with “Privacy Policy” in the subject.
                    Contact
                </div>
            </Card>

        )
    }
}
