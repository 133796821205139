import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppInlineProfile} from './AppInlineProfile';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {Route, Redirect} from 'react-router-dom';
import {withRouter} from 'react-router'
import {Profile} from "./components/profile";
import {MyAccounts} from "./components/My_Accounts/MyAccounts";
import {MainDashboard} from "./components/MainDashboard";
import {BankAccounts} from "./components/BankAccounts";
import {New_Top_Up_Account} from "./components/Top_Up_Account/New_Top_Up_Account";
import {Payment} from "./components/payment";
import {ListInvoice} from "./components/InvoicePayment/ListInvoice";
import ResetPassword from "./components/ResetPassword";
import {changeStyleSheetUrl, savePath, validateKYCComplete} from "./util/commonFunctions";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'font-awesome/css/font-awesome.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import {SendRequestFunds} from "./components/SendRequestFunds";
import {AccountActivity} from "./components/My_Accounts/AccountActivity";
import {OrderCard} from "./components/Cards/OrderCard";
import {Token} from "./components/Token";
import {KYC} from "./components/KYC";
import {Register_Account} from "./components/RegisterAccount";
import {BackendService} from "./service/wallet_backend/BackendService";
import {Cards} from "./components/Cards";
import {Pay_vendors} from "./components/Pay_vendors";
import AddNewWallet from "./components/AddNewWallet";

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            profileMode: 'top',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: true,
            menuActive: false,
            theme: 'blue',
            layout: 'blue',
            version: 'v4',
            configDialogActive: false,

            logout: false,
            usuarioSesion: null,
            login: false,
            gridObjeto: {},
            mostrarBandejaEnFooter: false,
            configObjecto: {},
            recibidosTopbar: 0,
            atendidosTopbar: 0,
            activeIndexBandeja: 0
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeProfileMode = this.changeProfileMode.bind(this);
        this.changeVersion = this.changeVersion.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.createMenu();

        this.onHandleClickProfile = this.onHandleClickProfile.bind(this);
        this.actualizarTotalTopbar = this.actualizarTotalTopbar.bind(this);
        this.actionToAccount = this.actionToAccount.bind(this);

        this.backendService = new BackendService();
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');

        //hide menu if kyc uncompleted
        if (validateKYCComplete() !== true) {
            //this.setState({staticMenuDesktopInactive: true});
        }
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        //blocked menu if kyc uncompleted
        if (validateKYCComplete() === true) {
            this.menuClick = true;
            this.setState(({
                rotateMenuButton: !this.state.rotateMenuButton,
                topbarMenuActive: false
            }));

            if(this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else {
                if(this.isDesktop())
                    this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
                else
                    this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
            }

            event.preventDefault();
        }
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if(this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.hideOverlayMenu();
        }
        if(!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onConfigButtonClick(event){
        this.configClick = true;
        this.setState({configDialogActive: !this.state.configDialogActive})
    }

    onConfigCloseClick(){
        this.setState({configDialogActive: false})
    }

    onConfigClick(){
        this.configClick = true;
    }

    onDocumentClick(event) {
        if(!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if(!this.menuClick) {
            if(this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.configClick) {
            this.setState({configDialogActive: false});
        }

        if(!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.configClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeMenuMode(event) {
        this.setState({layoutMode: event.layoutMode})
        if(event.layoutMode === 'horizontal') {
            this.setState({profileMode: 'top'})
        }
    }

    changeMenuColor(event) {
        this.setState({darkMenu: event.darkMenu})
    }

    changeProfileMode(event) {
        this.setState({profileMode: event.profileMode})
    }

    changeVersion(event) {
        this.setState({version:event.version});
        if(event.version === 'v3'){
            changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            changeStyleSheetUrl('layout-css', this.state.layout+'-v4', 'layout');
            changeStyleSheetUrl('theme-css', this.state.theme+'-v4', 'theme');
        }
    }

    changeLayout(event) {
        this.setState({layout: event.layout});
        if(this.state.version === 'v3'){
            changeStyleSheetUrl('layout-css', event.layout, 'layout');
        } else {
            changeStyleSheetUrl('layout-css', event.layout+'-v4', 'layout');
        }

        if(event.special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeTheme(event) {
        this.setState({theme: event.theme});
        if(this.state.version === 'v3'){
            changeStyleSheetUrl('theme-css', event.theme, 'theme');
        } else {
            changeStyleSheetUrl('theme-css', event.theme+'-v4', 'theme');
        }
    }

    createMenu() {
        this.menu = [
            {label: 'Dashboard', icon: 'fa fa-tachometer', to: '/' },
            {label: 'Top Up Account', icon: 'fa fa-money', to: '/top_up_account'},
            {label: 'Quick Checkout', icon: 'fa fa-check-circle-o', to: '/quick_checkout'},
            //{label: 'Linked Accounts', icon: 'pi pi-wallet', to: '/bank_accounts' },
            //{label: 'Account Activity', icon: 'fa fa-list-alt', to: '/account_activity'},

            {label: 'Send/Request Funds', icon: 'fa fa-money', to: '/send_request_funds'},
            //{label: 'Add a Friend Wallet', icon: 'fa fa-plus-square-o', to: '/registerAccount' },
            {label: 'Manage Card', icon: 'fa fa-caret-square-o-up', to: '/order_card'},
            {label: 'Add New Wallet', icon: 'fa fa-plus-square', to: '/add_new_wallet'},
            //{label: 'Pay Vendors', icon: 'fa fa-users', to: '/Pay_vendors'},
            //{label: 'Token', icon: 'fa fa-shield', to: '/token'},
            //{label: 'My Accounts', icon: 'pi pi-wallet', to: '/my_accounts' },
        ];
        //hide menu if kyc uncompleted
        if (validateKYCComplete() !== true) {
            this.menu.push({label: 'KYC', icon: 'pi pi-id-card', to: '/kyc'});
        }
    }

    onHandleClickProfile = (e) =>  {
        this.props.history.push("/kyc");
    }
    onHandleClickMessages= (e) =>  {
        this.props.history.push("/received_messages");
    }
    onHandleClickFAQ= (e) =>  {
        this.props.history.push("/faq");
    }

    actualizarTotalTopbar(recibidosTopbar, atendidosTopbar){
        this.setState({
            recibidosTopbar,
            atendidosTopbar
        });
    }

    actionToAccount () {
        this.setState({opcionMenuSelected: 'Account'});
    }

    onHandleClickResetFile = (e) => {
        this.props.history.push("/restPassword");
    }

    onLogout(){
        localStorage.clear();
        sessionStorage.clear();
        let logout = true;
        if (this !== undefined){
            if(this.handle){
                clearInterval(this.handle);
            }
            this.setState({
                logout
            });
        }
    }

    render() {
        if (JSON.parse(localStorage.getItem('login')) == null) {
            const pathname = this.props.location.pathname;
            savePath(pathname)
            return (<Redirect to={`/login`}/>)
        }

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                               topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                               onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                               onTopbarItemClick={this.onTopbarItemClick} onLogout={this.onLogout}
                               profile={{'userName': localStorage.getItem("userLogged"), 'area': ''}}
                               account={JSON.parse(localStorage.getItem('profile'))}
                               onHandleClickProfile={this.onHandleClickProfile}
                               onHandleClickMessages={this.onHandleClickMessages}
                               onHandleClickFAQ={this.onHandleClickFAQ}
                               onHandleClickResetFile={this.onHandleClickResetFile}
                    />
                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <div className="menu-scroll-content">
                            {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') &&
                                <AppInlineProfile onLogout={this.onLogout} profile={{'userName': localStorage.getItem("userLogged"), 'area': ''}}
                                                  onHandleClickProfile={this.onHandleClickProfile}/>}
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                     layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                        </div>
                    </div>

                    <div className="layout-main">
                        <Route path="/" exact component={MainDashboard} />
                        <Route path='/restPassword' component={ResetPassword}/>
                        <Route path='/add_new_wallet' component={AddNewWallet}/>
                        <Route path="/top_up_account" exact component={New_Top_Up_Account}/>
                        <Route path="/my_accounts" exact component={MyAccounts} />
                        <Route path="/bank_accounts" exact component={BankAccounts} />
                        <Route path="/kyc" exact component={KYC} />
                        <Route path="/kyc/:debug" exact component={KYC} />
                        <Route path="/profile" exact component={Profile} />
                        <Route path="/invoices" exact component={ListInvoice} />
                        <Route path="/send_request_funds" exact component={SendRequestFunds} />
                        <Route path="/Pay_vendors" exact component={Pay_vendors} />
                        <Route path="/registerAccount" exact component={Register_Account} />
                        <Route path="/quick_checkout" exact component={ListInvoice} />
                        <Route path="/account_activity" exact component={AccountActivity} />
                        <Route path="/token" exact component={Token} />
                        <Route path="/order_card" exact component={OrderCard} />
                        <Route path="/cards" exact component={Cards} />
                        <Route path="/payment/:code/:merchant_key" exact component={Payment} />

                        {/*
                            <Route path="/close-tab" exact component={CloseTab} />
                            <Route path="/demos" exact component={FormsDemo} />
                            <Route path="/oldkyc" exact component={oldKYC} />
                            <Route path="/received_messages" exact component={Received_Messages} />
                            <Route path="/faq" exact component={Faq} />
                        */}
                    </div>

                    <div className="layout-mask"></div>
                    <AppFooter />
                </div>
            </div>
        );
    }
}

export default withRouter(App);
